import React, { useState } from "react";
import logo from "../Images/CycleParts_grün_weiß.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validator from "validator";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";
import axios from "axios";
const GlobalContact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
   
    e.preventDefault();
    if (!message || !name || !email || !phone) {
    
      toast.error(t("enterallfields"), {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (validator.isEmail(email)) {
      toast.success(t("messagesent"), {
        position: toast.POSITION.TOP_CENTER,
      });

      axios.post("https://api.bbf-bike.de/api/mailer/cp-cycleparts",{
        name: name,
        email: email,
        subject:"CP CycleParts Formular" ,
        nachricht: message,
        telephone: phone
      })
      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
     
    } else {
      toast.error(t("entervalidemail"), {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  const { t } = useTranslation();

  return (
    <div className="md:pt-[180px]  md:pb-20 lg:pb-24  flex justify-center items-center">
      <ToastContainer />
      <div className="relative xs:mb-[5px] md:mb-0">
        <div className="h-[750px] xs:w-[375px] md:w-[450px] bg-cp-black"></div>

        <div className="absolute -bottom-[6px] left-0">
          <div class="w-40  overflow-hidden inline-block relative">
            <div class=" h-80  bg-cp-green -rotate-45 transform origin-bottom-right"></div>
            {/* <div className='absolute bottom-0 left-0 text-cp-white p-4 font-Manrope-ExtraLight font-semibold text-[11px]'>
              <div>BBF BIKE GmbH</div>
              <div>Carenaallee 8</div>
              <div>D-15366 Hoppegarten</div>
              <div>www.cp-cycleparts.com</div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="font-Manrope-ExtraLight absolute flex flex-col gap-2 text-cp-white  justify-start pb-24">
        <div className="xs:my-5 md:my-10 font-square text-center xs:text-xl md:text-3xl font-bold">
          {t("getintouch")}
        </div>
        {/* <div className='flex flex-row gap-8'>
              <div className='flex  flex-row items-center gap-2 justify-center'>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAA
              DAklEQVR4nO2ZzWsTQRTA149UD4p6Uc9SBKOx2XkbBFF686IiWoMKSqHZmSSF4sWzEfwAxYtQknkboQoKrYde9N8QsaBUaXsVeqlU06KHrMxuY+
              q6yc422ewu5MG7hLyZ99v35s2bGUXpS1/60rFk3yQH8hW4wTjMMIQFimSNIZhBKLXHXqAI0xS162LuzgkURclX4CrjZCkoxyXAFhlXr3QQhew
              OhvA0LAD2HxA8KZWU7b5BogTBGsrJY18QFLWR0J1Gd9UNclkypZIDYrGF7TBrHZWliWeDu7xTiqvXQncWPTUrkVYwHQFHTQ99LRER8kUyxKsU4Z
              5uZI4Xy6kD+Up6iCHM9QKEIpmXicgPbwhYEQBO22JZO8IQaoHDcLLqHRGpgdSbrT8EudOLqCjdABmdGt7dyr5UGt7JEN5HH4RD3WsMUeujD4KW7m
              s3xvhk5nAsQHQjk24fkczRWIAwTli7MSiSuzEBgZlW9oWydoJxsh4PEITa+GRyj2vrz8mHoCFYF0HMfAVyTlvdIGd7AcG6CSI6ZLFnbLbNVdVjc
              QQxKWpjLvbvYgfCOCwXyicPOuxTjMOveIGgBTPrHIMiuR0/EHRf+OJ8HTsQhvCbIZxzjYxbmnGyLjbNXFUdzFVPHdrozXw1mkGBWGeEQllT3TdIeL
              spFT+K35z/ExWQ2keAWrgg9sntW6s+bKM0nxGbZrv5i/bhbC5UkEZk3NLMj+hGJh0+SHPN6FsFGZ0a2h8VkMZ6mHXuMzKSty8yIgRiwyyLDsDZzrT
              1gZMH0QNpFoJFkW5jz0/v9Zg/xTj87BzEXqxmgEBr1jsLh4Io12I9iHnFhQZF7RZD+N6d6yDZC7owlcNnCRB4Fbqj6BnVl94gCNnog2gjMiAJivA1u
              mlFROonFBnJV+CSuIiLHgTUdYNckIL4GxkOj6KXUuS+4lfEwyNFeBiJyHCoWxCmss03SDMy6sUwSzJFMs8Qzm8Z4B8YhIQ4/IiyRxE+ifeRAL/+ijU
              Hwgsxp5/Wpi996YvSUv4AIzvril5dLwEAAAAASUVORK5CYII=" className='h-10 w-10 object-contain'/>
                <div className='font-semibold'>+4903342354325</div>
              </div>
              <h2 className='flex items-center gap-2 justify-center my-4'>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACt0lEQV
              R4nO1ZO2sUURSeaCGosbSxECQptIiZcxaLCIKK4CsoSDqrTe4ZFdKkEbQIIgFBm4BkzkkWBAuLRQVtfEASWysRU+gvEAk+1qQRjSt3d6KTyWZndmdnZ7
              LeDw7s495zv2/OY87OWpaBgUFTcFw8RwyviHGZBMupGuOyEpgntgcbEqEEbqVOXmqbEpyIHokMEKY6NjINZ0OFVNIpA2Spvs1FSCtcygDRcl1j+B4ek
              bRJSjQzQigDUSATEUn/ypOJiPwHEXHc/l6rTXDc/t7EhCiBkh5hkhZBbJ9Ugl+SrRHG33qotMpWV8sVlK0uYrxKDL8STS0leF0Jrnjvixfv9+1olQbt
              iwSL3jkr+qzEhOjv9eRJgt+86Ly9PJXbF1eE9qF9UfUc7ftMFC6xhPwtRIaFqhj47Lh4olkRxPYRJfjJ8/VheMbe3wiXWEI0RicP7VKCT70m8JMYxxoX
              gWOVvVURT7TPZrjEEuIvztW6UQIPSHB72Bmjkz3biPGev3mMj1tbYnFpxea1dQNv8gV770ZrHffgHiXw2ivqJSW5C63kEmuzktypQN9fJMGjwXWOi4eV
              wMd/XRBKJHh+I7/UztTyt2Ql+EgJvPRS5gcx3rg01bdbm35d+azq6wUxPva32lr3JWqHkHxhoFsTXyVDDNc0maHi0FYSvKNzf50P/Rnjbb2m1kXIFwa
              62ypkbfvFr46Lp9f5Yfs4MT730mxRCT4bmYZjwXV6r/bdivdeCf55LVIi/HpTAe3/fbxbDM3aPEngXrJv2jCgMD6/cPbDTahGCqaqSHFHC+v4mGhqhM8Z
              4p1N+WFGGzDJCJP0okImIpH/lyUREOiQiahP80aMEShGEwHzaRCnMGGfD7yNsD6ZOVEKt8rgoSnpNZDitblqNwHugMJeFmlGaA+Ns5EgYGBhYQfwBBY
              zpqxAcuP4AAAAASUVORK5CYII=" className='h-10 w-10 object-contain'/>
                <div  className='font-semibold'>info@bbf-bike.de</div>
              </h2>
              </div> */}
        <div className="flex flex-col justify-center items-center font-Manrope-ExtraLight">
          <div className="flex flex-col">
            <label>Name</label>
            <input
              type="text"
              onChange={(e) => setName(e.target.value)}
              value={name}
              className="text-cp-black outline-0 p-2 my-2 w-60"
              placeholder={t("entername")}
            />
          </div>
          <div className="flex flex-col">
            <label>Email</label>
            <input
              type="text"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className="text-cp-black outline-0 p-2 my-2 w-60"
              placeholder={t("enteremail")}
            />
          </div>
          <div className="flex flex-col">
            <label>{t("phone")}</label>
            <input
              type="number"
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              className="text-cp-black outline-0 p-2 my-2 w-60 focus:outline-none 
          [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              placeholder={t("enterphone")}
            />
          </div>
          <div className="flex flex-col">
            <label className="mt-10">{t("message")}</label>
            <textarea
              type="text"
              className="h-40 w-60 text-cp-black outline-0 p-2 my-2"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder={t("entermessage")}
            />
          </div>
        </div>
        <div className="xs:ml-16 md:ml-20 mt-5" onClick={handleSubmit}>
          <Button text={t("send")} color="cp-green" />
        </div>

        {/* <button
          className="bg-cp-green w-24 xs:ml-16 md:ml-20 mt-5 p-1  
          hover:bg-opacity-80 rounded-lg"
          onClick={handleSubmit}
        >
          {t("send")}
        </button> */}
      </div>
    </div>
  );
};

export default GlobalContact;
