import React from "react";


import Footer from "./Components/Footer";
import "./custom-font.css";
import { Route, Routes, useParams } from "react-router-dom";
import Home from "./Pages/Home";
import ProductPage from "./Pages/ProductPage";
import Subcategory from "./Components/Subcategory";
import Articles from "./Components/Articles";
import SubCategoryPage from "./Pages/SubCategoryPage";
import ContactPage from "./Pages/ContactPage";
import Stocks from "./Components/HandlerSuche";
import Services from "./Components/Services";
import HandlerSuche from "./Components/HandlerSuche";
import HeaderSection from "./Components/HeaderSection";
import DataProtection from "./Pages/DataProtection";
import Impressum from "./Pages/Impressum";
import Hinweise from "./Components/Hinweise";
import Downloads from "./Components/downloads";

const App = () => {
  const {partid}= useParams()
  return (
    <div>
  <HeaderSection/>
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/contact" element={<ContactPage/>}/>
      <Route path="/products" element={<ProductPage/>}/>
      <Route path="/products/:productname" element={<SubCategoryPage/>}/>
      <Route path="/products/articles/:productname" element={<Articles/>}/>
      <Route path="/products/articles/:productname/:partid" element={<Articles/>}/>
      <Route path="/handlersuche" element={<HandlerSuche/>}/>
      <Route path="/service" element={<Services/>}/>
      <Route path="/datenschutz" element={<DataProtection/>}/>
      <Route path="/impressum" element={<Impressum/>}/>
      <Route path="/hinweise" element={<Hinweise/>}/>
      <Route path="/downloads" element={<Downloads/>}/>
    </Routes>
<Footer/>
    </div>
  );
};

export default App;
