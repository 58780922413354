import React from 'react'
import { useTranslation } from 'react-i18next'
import services from "../Data/services.json"
import CategoryCard from './CategoryCard'
import image from "../Images/anleitungen.jpg"
import { useEffect } from 'react'
const Hinweise = () => {

    const{i18n}= useTranslation()
    const currentlanguage= i18n.language
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    return (
    <div className="bg-cp-black">
    <div className="min-h-[calc(100vh-140px)] h-auto max-w-[1920px] m-auto">
        
        <div className='xs:pt-2 lg:pt-[40px] xl:pt-[100px]'>
            {
                services.services.filter((item)=>item.name.de==="Hinweis & Anleitungen").map((item,index)=>(
                    <div key={index} className='flex justify-center items-center flex-col'>
                          <p className='text-cp-green md:text-[32px] xs:text-[20px]   font-Manrope-ExtraLight font-bold xs:pt-8 md:pt-[140px] xl:pt-20 '>
                        {currentlanguage==="de"?item.name.de:item.name.en}</p>
                        <h3 className='flex justify-center xl:flex-row xs:flex-col items-start xs:mt-10 xl:mt-20 xs:mb-14 xl:mb-28 xs:gap-10 xs:mx-5 xl:mx-0 xl:gap-20'>
                            <img src={image} className='object-contain'/>
                            
                            <div className='flex justify-center  items-start flex-col gap-5'>
                            
                                                        {item.documents.map((item,index)=>(
                                                            <div>
                            <p key={index} className='text-cp-white font-Manrope-ExtraLight flex justify-center items-center xs:text-[12px] md:text-[15px] xl:text-[18px]'>
                                <a href={item.link} target='_blank' className='hover:text-cp-green flex justify-center items-center gap-2'>
                    <p className="hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-0.5 before:origin-right before:transition-transform before:duration-500 before:scale-x-0 before:bg-cp-green before:absolute before:left-0 before:bottom-0">{item.name}</p>  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABv0lEQVR4nO2ZzUrDQBDHAz2qz+ChJz8QulMvvkduarV2R/FY8FLxLUR3ag/a476E4NdFb/Uu+AK+QKGyNSlpSeqmTZulnT8M5Gt355f/7CawnsdisZZbtaY4QiU+UYkuEvRyDSW6/VwIKqkgUJWOc0+eEsMeJqDvuRiSRCcNSP7lRMllZg+Sd7I0PvIA8SN9+gxC7AhwaSHPEeBVi5df5O8I8Jcd+ReFlvRfS5L4lgRv1iBKvJo2ToHIv4SK++2dFUni8T8QSeKl2tpbq7ZK65Lgyw0QBT8GImx7fr25igTPiSAKnswzkevFoI/8HUGCq2j7iDNDIKETQ2MpceGGIzSo+Ua0D/PWT+5KG+G5OY46EUA0nJojmODM2DEmcALnCGIFMw0ETgsycl9PCmMBodPkMmuQWBhLJ7RrID1JcBm2MceWpaOdAwlg3lHBR4o5oJ0EmSA0g4wq7k352i8M7iuoB65kHwrq4ThmzMxLq9bc3fLmrLOb8nbmIEhwP9Os4/JQoj0LkD6McSZaZlnL137BOCFJPCTlkQWIE+Et30aPWpStN4KKuyDlA2uQAYxD29OSROf0Fg5TQbBYrMXTLyU0qtjUbh79AAAAAElFTkSuQmCC" className='xs:h-5 xs:w-5 md:h-7 md:w-7 object-contain'/>
                                </a>
                            </p>
                            <div className='flex justify-center items-start flex-col text-cp-white xs:mt-1 md:mt-2 xs:text-[10px] md:text-[13px]'>
                                    
                                    {item.filesize}
                                    
                                    </div>
                            </div>
                        ))}</div></h3>
                    </div>
                  
                ))
            }
           
            <h3></h3>
        </div>
        
        </div> <div className="xs:h-[5px] lg:h-[10px] bg-cp-white"></div></div>
  )
}

export default Hinweise