import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "./Button";
import {TileLayer,MapContainer,Marker,Popup,ZoomControl} from "react-leaflet"
import "leaflet/dist/leaflet.css";
import locationIcon from "../Images/pin_3177361.png"
import phone from "../Images/phone-call.png"
import web from  "../Images/web.png"
import email from "../Images/email.png"
import { useTranslation } from "react-i18next";
import MarkerSection from "./MarkerSection";
const HandlerSuche = () => {
  const [searchedItem, setSearchedItem] = useState("");
  const[cpStores,setCpStores]= useState([])
  const[filteredItems,setFilteredItems]=useState([])
  const[locations,setLocations]= useState([])

const {t}= useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const getLocations= async()=>{
  const response= await axios.get("https://api.bbf-bike.de/api/reseller")
  setLocations(response.data)
  }
  const sampleStores= locations.filter((item)=>item.tag.includes("viva"))// sample map data for organizing everything correctly
  useEffect(()=>{
    getLocations()
  },[])
useEffect(()=>{
setCpStores(sampleStores)
},[locations])
 const getSelectedStores= (place)=>{
  if (place) {
    const lowerCaseNameOrOrt = place.toLowerCase();
    const filtered = sampleStores.filter(
      (item) =>
        item.name.toLowerCase().includes(lowerCaseNameOrOrt) ||
        item.ort.toLowerCase().includes(lowerCaseNameOrOrt)
    );
    setFilteredItems(filtered)
 }

}

  return (
    <div className="min-h-[calc(100vh-140px)] h-auto bg-cp-black ">
      <div className="lg:mt-[100px] font-Manrope-ExtraLight">
        <div className="flex justify-center xs:flex-col lg:flex-row items-center xs:pt-[60px] md:pt-[150px] xl:pt-[100px] xs:gap-6 lg:gap-16 font-Manrope-ExtraLight">
          <input
            type="text"
            placeholder={t("locationenter")}
            className="p-3 xs:w-60 lg:w-80 xs:h-10 lg:h-12 outline-none"
            onChange={(e) => setSearchedItem(e.target.value)}
          />
          <div onClick={()=>getSelectedStores(searchedItem)}>
          <Button text={t("search")} color="cp-green"/>
          </div>
          
        </div>
        <div className="flex justify-center items-center xs:mt-10 md:mt-12 xl:mt-28">
        <div className="xl:h-[500px] xl:w-[1200px] lg:w-[900px] lg:h-[550px] md:h-[350px] md:w-[710px] xs:w-[360px] xs:h-[300px]  xs:px-4 md:px-0  z-0">
          <MapContainer    center={[51.1657, 10.4515]}
          zoom={5}
          style={{ height: "100%", width: "100%" }}
          zoomControl={true}
          className="z-0 xs:hidden md:block">
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <ZoomControl position="topright"/>

     <MarkerSection data={sampleStores}/>

          </MapContainer>
          <MapContainer    center={[51.1657, 10.4515]}
          zoom={4.3}
          style={{ height: "100%", width: "100%" }}
          zoomControl={true}
          className="z-0 xs:block md:hidden">
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <ZoomControl position="topright"/>

     <MarkerSection data={sampleStores}/>

          </MapContainer>
        </div>
        </div>
        <div className="flex justify-center items-center xs:py-10 lg:py-40">
        <div className="grid md:grid-cols-2 xs:grid-cols-1 xs:gap-3 xl:gap-10">
          {sampleStores.map((item)=>
              
              <div className=" xs:w-[330px] lg:w-[553px] md:w-[350px] xs:h-[200px] md:h-[230px] lg:h-[306px] bg-cp-white relative ">
                <div class="xs:w-16 lg:w-24 overflow-hidden inline-block absolute bottom-0">
                  <div class=" h-[250px]  bg-cp-green -rotate-45 transform origin-bottom-right"></div>
                </div>
             
                <div className="flex justify-center flex-col items-center xs:mt-5 lg:mt-10 font-Manrope-ExtraLight ">
                  <div className="xs:mx-5 lg:mx-0 flex justify-center items-start gap-2 flex-col xs:w-[250px] md:w-[250px] xl:w-[400px]">
                    <div className="font-bold lg:mb-5 xs:mb-2 text-center xs:text-[16px] lg:text-[20px]">
                      {item.name}
                    </div>
                    <div className="flex items-center gap-4">
                      <img
                      src={locationIcon}
                      className="xs:h-5 xs:w-5 xl:h-8 xl:w-8 object-contain"
                      />
                      <h3 className="xs:text-[12px] md:text-[16px]">{item.adresse}</h3>
                    </div>
                    <div className="flex items-center gap-4 ">
                      <img
                       src={phone}
                       className="xs:h-5 xs:w-5 xl:h-8 xl:w-8 object-contain"
                      />
                      <h3 className="xs:text-[12px] md:text-[16px]">{item.tel}</h3>
                    </div>
                    <div className="flex items-center gap-4">
                      <img
                       src={email}
                       className="xs:h-5 xs:w-5 xl:h-8 xl:w-8 object-contain"
                      />
                      <h3 className="xs:text-[12px] md:text-[16px]">{item.email}</h3>
                    </div>
                  {/* {item.web?(
                     <div className="flex items-center gap-4">
                     <img
                     src={web}
                      className="xs:h-5 xs:w-5 xl:h-8 xl:w-8 object-contain"
                     />
                     <h3 className="xs:text-[12px] md:text-[16px]">{item.web}</h3>
                   </div>
                  ):""} */}
                   
                  </div>
                </div>
              </div>
          
            
          )}</div>
      
        </div>
        <div className="bg-cp-white xs:h-[5px] lg:h-[10px]"></div>
      </div>
    </div>
  );
};

export default HandlerSuche;
