import React from 'react'

import bannerImage from "../Images/_MG_9745.jpg";
import { useTranslation } from "react-i18next";
const WelcomeSection = () => {
  const {t}= useTranslation()
  return (
    <div className="md:my-[10px] xs:my-[5px] overflow-hidden">
    <div className="xs:flex 2xl:hidden flex-col bg-cp-black">
    <img
          src={bannerImage}
          className="object-cover xs:h-[250px] xl:h-[370px]"
          alt="banner"
        />

      <div className="flex-col justify-center  flex xs:mx-6 my-5 lg:mx-28">
        <div className="text-cp-green font-Manrope-ExtraLight   font-bold xs:text-[10px] sm:text-[12px] md:text-[16px] xl:text-[22px] md:mb-4 xs:mb-1">
        {t("welcome")}
        </div>
        <h2 className="text-cp-white font-Manrope-ExtraLight xs:text-[10px] md:text-[13px] xl:text-[15px] text-left">
        {t("sectiondesc")} <br />
          <h4 className=" md:leading-4 xs:leading-3 md:mt-5 xl:mt-8 xs:mt-2 ">
            <div className="flex  items-center gap-2">
            {t("bulletpoints1")}
            </div>
            <br />
            <div className="flex items-center gap-2">
            {t("bulletpoints2")}
            </div>
            <br />
            <div className="flex items-center gap-2">
            {t("bulletpoints3")}
            </div>
            <br />
            <div className="flex  items-center gap-2">
            {t("bulletpoints4")}
            </div>
            <div className="flex mt-4 items-center gap-2">
              <div className="text-cp-green font-Manrope-ExtraLight  font-bold xs:text-[10px] sm:text-[12px] md:text-[16px] xl:text-[22px] md:mt-4 xs:mt-1">
              {t("lastsentence")}
              </div>
            </div>
          </h4>
        </h2>
      </div>
    </div>

    <div className=" justify-start relative w-screen 2xl:flex xs:hidden">
      <div
        className="2xl:h-[500px] xl:h-[400px] md:h-[400px] md:w-[64%] lg:w-[45.5%] xs:h-[250px] bg-cp-black xs:w-[62%] xl:w-[70%] 2xl:w-[1080px] flex float-left aspect-[1/1] lg:aspect-[1/2]  
                                      [clip-path:circle(70%_at_20%_30%)] 
                                      [shape-outside:circle(70%_at_20%_30%)]
                                      2xl:[clip-path:polygon(0%_0%,100%_0%,87%_100%,0%_100%)]
                                      2xl:[shape-outside:polygon(0%_0%,100%_0%,75%_100%,0%_100%)]  
                                      xl:[clip-path:polygon(0%_0%,100%_0%,88.2%_100%,0%_100%)]
                                      xl:[shape-outside:polygon(0%_0%,100%_0%,75%_100%,0%_100%)] 
                                      lg:[clip-path:polygon(0%_0%,100%_0%,88.2%_100%,0%_100%)]
                                      lg:[shape-outside:polygon(0%_0%,100%_0%,75%_100%,0%_100%)] 
                                      md:[clip-path:polygon(0%_0%,100%_0%,84%_100%,0%_100%)]
                                      md:[shape-outside:polygon(0%_0%,100%_0%,75%_100%,0%_100%)] 
                                      sm:[clip-path:polygon(0%_0%,100%_0%,67%_100%,0%_100%)]
                                      sm:[shape-outside:polygon(0%_0%,100%_0%,75%_100%,0%_100%)]  
                                      xs:[clip-path:polygon(0%_0%,100%_0%,67%_100%,0%_100%)]
                                      xs:[shape-outside:polygon(0%_0%,100%_0%,75%_100%,0%_100%)]"
      >
        <div className="flex-col justify-center xs:ml-4 md:ml-12 2xl:ml-44 flex ">
          <div className="text-cp-green font-Manrope-ExtraLight xs:w-[140px] md:w-[700px]  font-bold xs:text-[14px] md:text-[16px] xl:text-[22px] md:mb-4 xs:mb-1">
          {t("welcome")}
          </div>
          <h2 className="text-cp-white xs:w-[140px] md:w-[350px] xl:w-[700px] font-Manrope-ExtraLight xs:text-[12px] md:text-[15px] xl:text-[18px] ">
          {t("sectiondesc")} <br />
            <h4 className=" md:leading-4 xs:leading-3 md:mt-5 xl:mt-8 xs:mt-2 xs:hidden md:block">
              <div className="flex  items-center gap-2">
              {t("bulletpoints1")}
              </div>
              <br />
              <div className="flex items-center gap-2">
              {t("bulletpoints2")}
              </div>
              <br />
              <div className="flex items-center gap-2">
              {t("bulletpoints3")}
              </div>
              <br />
              <div className="flex  items-center gap-2">
              {t("bulletpoints4")}
              </div>
              <div className="flex mt-4 items-center gap-2">
                <div className="text-cp-green font-Manrope-ExtraLight xs:w-[140px] md:w-[700px]  font-bold xs:text-[14px] md:text-[16px] xl:text-[22px] md:mt-4 xs:mt-1">
                {t("lastsentence")}
                </div>
              </div>
            </h4>
          </h2>
        </div>
      </div>
      <div
        className="xl:h-[500px] xs:h-[250px] md:h-[400px] md:w-[553px] lg:w-[600px] xs:w-[350px] xl:w-[953px] 2xl:w-[973px] float-left aspect-[1/1] lg:aspect-[1/2] object-center
                                      [clip-path:circle(70%_at_20%_30%)] 
                                      [shape-outside:circle(70%_at_20%_30%)]
                                      2xl:[clip-path:polygon(0%_0%,100%_0%,85.5%_100%,0%_100%)]
                                      2xl:[shape-outside:polygon(0%_0%,100%_0%,75%_100%,0%_100%)] 
                                      xl:[clip-path:polygon(0%_0%,100%_0%,86.2%_100%,0%_100%)]
                                      lg:[shape-outside:polygon(0%_0%,100%_0%,82%_100%,0%_100%)]
                                      lg:[clip-path:polygon(0%_0%,100%_0%,91%_100%,0%_100%)]
                                      xl:[shape-outside:polygon(0%_0%,100%_0%,75%_100%,0%_100%)]
                                      md:[clip-path:polygon(0%_0%,100%_0%,85.5%_100%,0%_100%)]
                                      md:[shape-outside:polygon(0%_0%,100%_0%,75%_100%,0%_100%)] 
                                      xs:[clip-path:polygon(0%_0%,100%_0%,78%_100%,0%_100%)]
                                      xs:[shape-outside:polygon(0%_0%,100%_0%,75%_100%,0%_100%)] rotate-180 absolute inset-0 xl:ml-[770px] md:ml-[420px] 2xl:ml-[950px] xs:ml-40"
      >
        <img
          src={bannerImage}
          className="object-cover rotate-180 "
          alt="banner"
        />
      </div>
    </div>
  </div>
  )
}

export default WelcomeSection