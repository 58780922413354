import { t } from "i18next";
import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { Button } from "./Button";
import closeIcon from "../Images/CloseIcon.png"
const Dailog = ({ selectedBlock, closeHandler, articles }) => {
  const [image, setImage] = useState(0);
  const[description,setDescription]=useState(true)
  const[properties,setProperties]= useState(false)
  const { partid } = useParams();
  const getData = articles
    ?.filter((item) => item.PARTNO === partid)
    .find((item) => item);
  const findColor = (value) => {
    if (getData) {
      for (const x of getData?.properties?.default?.secondary) {
        if (x?.ATTRIBUTE === value) {
          return x?.VALUE;
        }
      }
    }
    return null;
  };
  const color = findColor("Farbe primär");
 
  const getFullImage = (index) => {
    setImage(index);
  };
  const {t}= useTranslation()
  const descriptionSelected=()=>{
setDescription(true)
setProperties(false)
  }
  const propertiesSelected=()=>{
    setProperties(true)
    setDescription(false)
  }
  return (
    <div>
      <div
        className="fixed inset-0 z-50 lg:flex md:flex-row md:items-center md:justify-center
    sm:flex sm:flex-col  overflow-x-hidden    outline-none focus:outline-none 
  transition-transform  duration-700 transform 
  hover:opacity-100 backdrop-blur-sm md:bg-cp-black xs:bg-cp-black shadow-xl animate-fade"
      >
        <div className="absolute md:right-5 md:top-40 lg:right-16 lg:top-8 xl:right-[70px] 2xl:right-40 2xl:top-12 xs:right-4 xs:top-5">
        <img src={closeIcon} className=" xl:h-6 xl:w-6  xs:h-4 xs:w-4 object-contain cursor-pointer hover:opacity-90"
            onClick={closeHandler}/>
          {/* <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACX
            BIWXMAAAsTAAALEwEAmpwYAAADqklEQVR4nO2aTWgTQRSA13/FH0QQBUEQ9GBB28yLba1gT4IXvUgP/kDR
            ZF6gELDe1EMOtlYFwUgh85LWgheh1IMnL/71oKKiFrQIioKiCIIoIqJFW5k6W0PY3ZndbDah5MFedmffmz
            fv25eZ92JZdalLdMKJ3UaCKc01kcwzHpZNqQsJJnR25dx8OAJjBo5IpT8Shdjmcp2QOqQuNLIJY8aKkYDJ
            1eEEf1I52OE4RrBB5cyTjuGGhUGdyGTa56NgD5WuAZf5tKBgv+WVykGzLwOc2Bm1Ci86h9oXlz4/dHnrU
            hTwanqMYD1BHeEEvUrHm3S2eUXp83R24yIU7LmaS59vA8UKpFNOY2S05CrJyKGI7fTvRLzNfp9TvN15DJ
            zzWtAQEYPT9ooeGWhbbqpbRpQTvFTc94aOlF/EkGCBzTgKyFuGggQFr28sXS5SQRArzjqc4vt0OjnF96p
            I/ESCLRVDKghinKBbReVTV/+2tZaLHB5sWs2JfVQ4Hq04Un4Rs6asOZzguprgNctFULARFY0bmYw1t+JI
            BctijetQsM9qEkmXX28ZtS9IsD4ypAJlMYIDarLfU7mmTfb9ZL55Awr2TT5L5WB/5Eh5IdZ9vnWJCz5XF
            GJ3O4Y75kmEOMGoujfi9E7FkQqCWOdQ40okeKsmfoITnFTfxftEoXVV1ZAqFRl2GwGJg8uYXShgEgX8Ut
            ekvFd1pAIhRpCd2bkKuFATSJWKDD8nGPdCTDrICe7Jy+N7OquwG48MqSBZTPN+9ZAKglhNIuU0IU7smR
            diXkhFmqXCRqymkHJFTLCnlkbkGL8RjExmhSPoExV7fNBsVxEJmn20x4KoJWj2MdmzRSblZp+aQKzTcIu
            CAu7Lq2a3KGiAFCd2sajcma05xFKzYRufNshS8mDFib1Tq3xcHq7UVv6D28Eq8i0LGhh0OuqiYHfUt3C
            16oihAQLJPDuoKz7IAkXVsljaAKniclAqBwmHiSbV+1/dykFYacR0Bv7hAzd9FOhGdQU6HjZiJkihgGN
            2yTRRaFljUjKVZdbIEEsbIdXUMNM6E7E9Op0oYrtlOvYqYmPYiOkUyrYCJ3ikVpjM9ULe3vbr2gq8XMS
            MkCLoU8ZeB230yGZRxRBLGyFVXusNRWy7rvWGRUT4KW4YI9XV37DMboZyYqd8G/hvp8e0Gcr9ImaCFCe
            4pBx9XG57mhN7oJwZDBWxWfOHAW7yFw65o3Vo5gSV6V99Ma1T58ytsGzWpS6WXv4CljGF3w5/MiYAAAA
            ASUVORK5CYII="
            className="2xl:h-7 xl:h-6 xl:w-6 2xl:w-7 xs:h-4 xs:w-4 object-contain cursor-pointer hover:opacity-90"
            onClick={closeHandler}
          /> */}
        </div>
        <h3 className="text-cp-white font-Manrope-ExtraLight font-extrabold text-[20px] xs:block md:hidden text-center  my-10">
    
          {getData?.PRODCATNAME}
        </h3>
        <div
          className="flex md:flex-row xs:items-center md:items-start justify-center
         xs:flex-col   md:h-[60%] lg:h-[85%]   lg:mt-[0px] gap-5 md:mb-0 xs:mb-16"
        >
          {/* overflow-y-auto */}
          <div className=" h-full xl:w-[650px] 2xl:w-[800px] xs:w-[335px]  flex justify-center items-start bg-cp-white">
            <div className="">
              {/* {images?.images.map((img) => (
              
            
              <img
                src={`https://media.bbf-bike.de/shop/images/${img}`}
                alt="bike"
                className="md:w-56 md:h-56 xl:w-80 xl:h-80 object-contain p-4"
              />

            ))} */}

              <div className="flex justify-center items-center mt-10 box-border 2xl:h-[450px] xl:h-[350px] xl:w-[750px] 2xl:w-[850px]  xs:h-[150px] xs:w-[420px]">
                <img
                  src={`https://media.bbf-bike.de/shop/images/${getData?.media[image]?.IMAGENAME}`}
                  className="imageContainer"
                />
              </div>
              <div className="bg-cp-black h-[10px]"></div>
              <div
                className={`flex xs:flex-row md:flex-col lg:flex-row justify-center items-center h-40%  md:gap-4 xs:gap-0 xs:mt-2 xl:mt-16 2xl:mt-16 `}
              >
                {getData?.media[0]?.TYPE==="Bild"?( <img
                  src={`https://media.bbf-bike.de/shop/images/${getData?.media[0]?.IMAGENAME}`}
                  className={`2xl:h-[170px] 2xl:w-[170px] xl:h-[160px] xl:w-[130px] xs:h-[80px] xs:w-[80px] object-contain p-3 cursor-pointer   ${
                    getData?.media[0]?.IMAGENAME === undefined
                      ? "hidden"
                      : "block"
                  }`}
                  onClick={() => getFullImage(0)}
                />):""}
               
                {getData?.media[1]?.TYPE==="Bild"?(<img
                  src={`https://media.bbf-bike.de/shop/images/${getData?.media[1]?.IMAGENAME}`}
                  className={`2xl:h-[170px] 2xl:w-[170px] xl:h-[160px] xl:w-[130px] xs:h-[80px] xs:w-[80px] object-contain p-3 cursor-pointer   ${
                    getData?.media[1]?.IMAGENAME === undefined
                      ? "hidden"
                      : "block"
                  }`}
                  onClick={() => getFullImage(1)}
                />):""}
                {getData?.media[2]?.TYPE==="Bild"?(  <img
                  src={`https://media.bbf-bike.de/shop/images/${getData?.media[2]?.IMAGENAME}`}
                  className={`2xl:h-[170px] 2xl:w-[170px] xl:h-[160px] xl:w-[130px] xs:h-[80px] xs:w-[80px]  object-contain p-3 cursor-pointer   ${
                    getData?.media[2]?.IMAGENAME === undefined
                      ? "hidden"
                      : "block"
                  }`}
                  onClick={() => getFullImage(2)}
                />):""}
              
                {getData?.media[3]?.TYPE==="Bild"?(  <img
                  src={`https://media.bbf-bike.de/shop/images/${getData?.media[3]?.IMAGENAME}`}
                  className={`2xl:h-[170px] 2xl:w-[170px] xl:h-[140px] xl:w-[130px] xs:h-[80px] xs:w-[80px] object-contain p-3 cursor-pointer  ${
                    getData?.media[3]?.IMAGENAME === undefined
                      ? "hidden"
                      : "block"
                  }`}
                  onClick={() => getFullImage(3)}
                />):""}
              
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start  xs:mb-0 md:mb-10 md:h-full bg-cp-white 2xl:w-[500px] xl:w-[400px] xs:w-[380px] sm:w-[335px] md:w-[400px] md:px-5  md:py-0 xs:py-8 xl:px-10 2xl:px-10 font-Manrope-ExtraLight">
            <h3 className="text-cp-black pt-10  font-Manrope-ExtraLight font-extrabold text-2xl xs:mb-12 md:mb-5 xl:mb-5 md:block xs:hidden text-center md:mx-0 lg:mx-0">
              {getData?.PRODCATNAME}
            </h3>

            {/* <div className='flex'>
        {[
            ...new Set(selectedBlock.map((item) => item.color).flat()),
          ].map((item) => (
            <div className='my-5'>
              <div
                style={{
                  width: "22px",
                  height: "22px",
                  borderRadius: "30%",
                  margin: "5px",
                  backgroundColor: item,
                  cursor: "pointer",
                  border:"2px solid white"
                }}
                onClick={() => handleColorClick(item)}
                
              ></div>
            </div>
          ))}
        </div> */}
           
        
                <div className="flex flex-col justify-center items-start text-[14px] xs:mx-5 md:mx-0 lg:mx-0">
                  
                  
                  <div className="flex justify-center items-start gap-7">
                  <h3 className="font-bold  text-cp-black mb-2 lg:w-[150px] xs:w-[130px]">{t("partno")}</h3>
                  <h5 className="mb-2">
              {getData?.PARTNO}
              </h5>
                  </div>
                  <div className="flex justify-center items-start gap-7">
                  <h3 className="font-bold  text-cp-black mb-2 lg:w-[150px] xs:w-[130px]">EAN</h3>
              <h5 className="mb-2">
              {getData?.eans.map((item,index)=>(
                <div key={index}>{item.EAN}</div>
              ))}
              </h5>
                  </div>
             
           
                {/* <h3 className="font-bold  text-cp-green mb-2">{t("description")}</h3>
              <h5 className="">
{getData?.descriptions.default.NAME} 
              </h5> */}
                </div>
                {getData?.descriptions.default.TEXT5?(
                  <div className="flex justify-center items-center gap-10 xs:mt-8 md:mt-3 xl:mt-6 xs:mx-5 md:mx-0 lg:mx-0 font-Manrope-ExtraLight">
                   <p onClick={descriptionSelected}><Button text={t("description")} color={description? "cp-green":"cp-black"}/></p>
                   <p onClick={propertiesSelected}><Button text={t("properties")} color={properties? "cp-green":"cp-black"}/></p> 
                   
                  
                  </div>
                ):
                (    <div className="flex justify-start items-start xs:gap-5 md:gap-3 flex-col xs:mt-12 md:mt-3 xl:mt-12 text-[14px] xs:mx-5 md:mx-0 lg:mx-0 font-Manrope-ExtraLight">

                {getData?.properties.default.primary
                  .sort((a, b) => a.ATTRIBUTE.localeCompare(b.ATTRIBUTE))
                  .filter(
                    (item) =>
                      item.ATTRIBUTE !== "Artikelgruppe" &&
                      item.ATTRIBUTE !== "Marke"
                  )
                  .map((item,index) => (
                    <div key={index} className="flex justify-center items-start gap-7" >
                      <h2 className="font-bold text-cp-green lg:w-[150px] xs:w-[130px]">
                        {item.ATTRIBUTE}
                      </h2>
                      <h2>{item.VALUE}</h2>
                    </div>
                  ))}
                {getData?.properties.default.secondary.map((item,index) => (
                  <div className=" flex justify-center items-start gap-7" key={index}>
                    <div className="font-bold text-cp-green lg:w-[150px] xs:w-[130px]">
                      {item.ATTRIBUTE}
                    </div>
                    <h2 className="w-[150px]">{item.VALUE}</h2>
                  </div>
                ))}
              </div>)
                
                
                }
      
      <div className="flex justify-start items-start xs:gap-5 md:gap-3 flex-col xs:mt-8 md:mt-3 xl:mt-6 text-[14px] xs:mx-5 md:mx-0 lg:mx-0 font-Manrope-ExtraLight">
                   {description?(<div className="text-left">{getData?.descriptions.default.TEXT5}</div>):(<div className="flex justify-start items-start xs:gap-5 md:gap-3 flex-col text-[14px] font-Manrope-ExtraLight">
                   {getData?.properties.default.primary
                  .sort((a, b) => a.ATTRIBUTE.localeCompare(b.ATTRIBUTE))
                  .filter(
                    (item) =>
                      item.ATTRIBUTE !== "Artikelgruppe" &&
                      item.ATTRIBUTE !== "Marke"
                  )
                  .map((item,index) => (
                    <div key={index} className="flex justify-center items-start gap-7" >
                      <h2 className="font-bold text-cp-green lg:w-[150px] xs:w-[130px]">
                        {item.ATTRIBUTE}
                      </h2>
                      <h2>{item.VALUE}</h2>
                    </div>
                  ))}
                {getData?.properties.default.secondary.map((item,index) => (
                  <div className=" flex justify-center items-start gap-7" key={index}>
                    <div className="font-bold text-cp-green lg:w-[150px] xs:w-[130px]">
                      {item.ATTRIBUTE}
                    </div>
                    <h2 className="w-[150px]">{item.VALUE}</h2>
                  </div>
                ))}
                   </div>)} 
                   </div>
            {/* <h3 className="font-bold text-cp-green mt-5">Stock:</h3>
     <select className='w-[212] bg-cp-green p-1 mt-2 focus:outline-none' onChange={(e)=>setSelectedItem(e.target.value)}>
      {selectedBlock.stocks.map((item)=>(
           <option className=''>{item.NAME}</option>
      ))}
       </select>
       {selectedBlock.stocks.filter((stock)=>stock.NAME===selectedItem).map((stock,index)=>(
          <div key={index} className='mt-3'>
              <h3>
                  {stock.INSTOCK}
              </h3>
          </div>
       ))} */}

       {/* <div className="mt-6">{getData?.descriptions.default.TEXT5}</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dailog;
