import React  from "react";
import ServiceCard from "./ServiceCard";
import triangle from "../Images/lowertraingle.png";
import { useEffect } from "react";
const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-cp-black">
    <div className="min-h-[calc(100vh-140px)] h-auto max-w-[1920px] m-auto">
      <div className="xs:pt-12 lg:mt-[40px] 2xl:mt-[100px] bg-cp-black">
        <ServiceCard />
        {/* <h2 className="xs:flex flex-col justify-center items-center lg:hidden text-cp-white py-20">
          <div className="flex flex-col">
            <h2 className="text-[18px]">DOWNLOADS</h2>
            <div className="flex">
              <div className="h-[3px] w-64 bg-cp-green mt-2"></div>
            </div>
          </div>
          <div>
            <h2 className="text-[16px] mt-4">KATALOGE</h2>
            <h3 className="text-[10px] cursor-pointer">
              BBF BIKE 2021
              <br />
              pdf
            </h3>
            <div className="h-[1px] bg-cp-white w-64"></div>
          </div>
          <div>
            <h2 className="text-[16px] mt-4">KATALOGE</h2>
            <h3 className="text-[10px] cursor-pointer">
              BBF BIKE 2021
              <br />
              pdf
            </h3>
            <div className="h-[1px] bg-cp-white w-64"></div>
          </div>
          <div>
            <h2 className="text-[16px] mt-4">KATALOGE</h2>
            <h3 className="text-[10px] cursor-pointer">
              BBF BIKE 2021
              <br />
              pdf
            </h3>
            <div className="h-[1px] bg-cp-white w-64"></div>
          </div>
        </h2> */}
        {/* <div className="h-[5px] bg-cp-white lg:hidden xs:block"></div>
        <div className="h-[905px] relative w-full mt-20 xs:hidden lg:block">
          <img src={triangle} className="h-[905px] object-cover w-full" />
          <h2 className="absolute text-cp-white text-xl right-[400px] top-0 z-30 flex flex-col font-Manrope-ExtraLight mt-20 ">
            <div className="flex flex-col">
              <h2 className="text-[20px]">DOWNLOADS</h2>
              <div className="flex">
                <div className="h-[3px] w-64 bg-cp-green mt-2"></div>
              </div>
            </div>
            <div>
              <h2 className="text-[16px] mt-4">KATALOGE</h2>
              <h3 className="text-[10px] cursor-pointer">
                BBF BIKE 2021
                <br />
                pdf
              </h3>
              <div className="h-[1px] bg-cp-white w-64"></div>
            </div>
            <div>
              <h2 className="text-[16px] mt-4">KATALOGE</h2>
              <h3 className="text-[10px] cursor-pointer">
                BBF BIKE 2021
                <br />
                pdf
              </h3>
              <div className="h-[1px] bg-cp-white w-64"></div>
            </div>
            <div>
              <h2 className="text-[16px] mt-4">KATALOGE</h2>
              <h3 className="text-[10px] cursor-pointer">
                BBF BIKE 2021
                <br />
                pdf
              </h3>
              <div className="h-[1px] bg-cp-white w-64"></div>
            </div>
          </h2>
        </div> */}


      </div>
    </div>
    <div className="xs:h-[5px] lg:h-[10px] bg-cp-white"></div>
    </div>
  );
};

export default Services;
