import React, { useEffect } from 'react'
import SliderSection from '../Components/SliderSectionHome'
import WelcomeSection from '../Components/WelcomeSection'
import DiscriptionSection from '../Components/DiscriptionSection'

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <div className=''>
    <div className="min-h-[calc(100vh-140px)] h-auto max-w-[1920px] m-auto">
   <div className='md:mt-[100px] xs:mt-0'>
   <SliderSection/>
   <WelcomeSection/>
   <DiscriptionSection/>

   </div>
   </div>
    </div>
  )
}

export default Home