import React from "react";

export const Button = ({ text,color,status}) => {
  return (
    <div>
      <button 
        className={`bg-${color} p-3 xs:text-[13px] md:text-[15px]  
          hover:bg-opacity-80  font-Manrope-ExtraLight font-semibold text-cp-white`}
      >
        {text}
      </button>
    </div>
  );
};
