import React, { useEffect, useState } from "react";
import logo from "../Images/CycleParts_grün_weiß.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
const HeaderSection = () => {
    const [open, setOpen] = useState(false);
    const [mainCategory,setMainCategory]= useState([])
  const openedMenu = () => {
    setOpen(true);
    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = "hidden";
    }
  };
  const menuClosed = () => {
    setOpen(false);
    document.body.style.overflow = "unset";
  };
  const { i18n } = useTranslation();
  const handleSelectedLanguage = (selectedlanguage) => {
    i18n.changeLanguage(selectedlanguage);
    localStorage.setItem("language", selectedlanguage);
  };
 
  const { t } = useTranslation();
  const currentLanguage = i18n.language;

const findCategory=async()=>{
try{
  const response= await axios.get("https://data.bbf-bike.de/catalog/list/categories/37") 
  setMainCategory(response.data)
}
catch(err){
  console.log(err)
}
  
}
useEffect(()=>{
  findCategory()
},[])

const Fahrradteile= mainCategory?.filter((item)=>item?.bbf_cat_ID_PUBLICGRPORDERMAIN===1962)
const Fahrradzubehör= mainCategory?.filter((item)=>item?.bbf_cat_ID_PUBLICGRPORDERMAIN===1963)

console.log(Fahrradteile,Fahrradzubehör)

  return (
    <div>
        <div className='h-[100px] w-screen  md:fixed top-0 left-0 bg-cp-black z-50 '>
       <div className="max-w-[1920px] m-auto flex justify-between items-center md:px-20 lg:px-28 xs:px-6  relative h-[100px]">
        <div className="xs:block lg:hidden left-0 top-0 flex-1">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAPUlEQVR
      4nGNgGAWjYBRQG6TNND6cNtP4P43x4YGxeIbxIaqH2CgYBTjBaHYaBaOAaiBttHYaBaNgFDCQBgDUkVMW8/XTmQAAAABJRU5ErkJggg=="
            onClick={openedMenu}
          />
        </div>
        <div className="lg:flex gap-3 xs:hidden  text-cp-white font-square text-[17px] font-semibold flex-1">
              {" "}
              <ul className="flex gap-6 justify-center items-center ">
                <div className="relative group">
              <Link to="/products">
                <li className="cursor-pointer">
                  {t("products").charAt(0) +
                    t("products").slice(1).toLowerCase()}
                </li>
              </Link>
              
              <div className="hidden absolute left-0 py-16  text-white group-hover:block  w-[570px]   z-50">
              <div className="bg-cp-white py-8">
              <ul className="flex justify-between items-start">
                <li>
                  <Link to="/products/Fahrradteile">
                  <h2 className="block pl-8 pr-3 py-2 text-cp-black">
                  {t("bicycleparts")}</h2>
                  </Link>

                  {Fahrradteile.map((item,index)=>(
                      <div className="text-cp-black pl-12 pr-4 py-2 font-regular text-[15px] flex justify-center items-start flex-col gap-3" key={index}>
                      <Link to={`/products/articles/${item?.bbf_cat_name}`} className="hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-0.5 before:origin-right before:transition-transform before:duration-500 before:scale-x-0 before:bg-cp-green before:absolute before:left-0 before:bottom-0">{currentLanguage=="de"?item.bbf_cat_name:item.translations.english.NAME}</Link>
                         {/* <Link to="/products/articles/Vorbauten" className="hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-0.5 before:origin-right before:transition-transform before:duration-500 before:scale-x-0 before:bg-cp-green before:absolute before:left-0 before:bottom-0">
                          <p>Vorbauten</p>
                          
                         </Link>
                         <Link to="/products/articles/Pedale" className="hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-0.5 before:origin-right before:transition-transform before:duration-500 before:scale-x-0 before:bg-cp-green before:absolute before:left-0 before:bottom-0"><p>Pedale</p></Link>
                         <Link to="/products/articles/Lenker" className="hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-0.5 before:origin-right before:transition-transform before:duration-500 before:scale-x-0 before:bg-cp-green before:absolute before:left-0 before:bottom-0"><p>Lenker</p></Link>
                         <Link to="/products/articles/Griffe-und-Lenkerband" className="hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-0.5 before:origin-right before:transition-transform before:duration-500 before:scale-x-0 before:bg-cp-green before:absolute before:left-0 before:bottom-0"><p>Griffe und Lenkerband</p></Link>
                         <Link to="/products/articles/Fahrradreifen-und-Schläuche" className="hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-0.5 before:origin-right before:transition-transform before:duration-500 before:scale-x-0 before:bg-cp-green before:absolute before:left-0 before:bottom-0"> <p>Fahrradreifen und Schläuche</p></Link> */}
  
                    </div>
                  ))}
                
                </li>
                <li>
                  <Link to="/products/Fahrradzubehör">
                  <h2  className="block pl-8 pr-3 py-2 text-cp-black">
                  {t("accessories")}</h2>
                  </Link>
                  {Fahrradzubehör.map((item,index)=>(
 <div className="text-cp-black pl-12 pr-8 py-2 font-regular text-[15px] flex justify-center items-start flex-col gap-3" key={index}>
   <Link to={`/products/articles/${item?.bbf_cat_name}`} className="hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-0.5 before:origin-right before:transition-transform before:duration-500 before:scale-x-0 before:bg-cp-green before:absolute before:left-0 before:bottom-0">{currentLanguage=="de"?item.bbf_cat_name:item.translations.english.NAME}</Link>
 {/* <Link to="/products/articles/Rucksack" className="hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-0.5 before:origin-right before:transition-transform before:duration-500 before:scale-x-0 before:bg-cp-green before:absolute before:left-0 before:bottom-0"><p>Rucksack</p></Link>
 <Link to="/products/articles/Standpumpen" className="hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-0.5 before:origin-right before:transition-transform before:duration-500 before:scale-x-0 before:bg-cp-green before:absolute before:left-0 before:bottom-0"><p>Standpumpen</p></Link>
 <Link to="/products/articles/Taschen" className="hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-0.5 before:origin-right before:transition-transform before:duration-500 before:scale-x-0 before:bg-cp-green before:absolute before:left-0 before:bottom-0"><p>Taschen</p></Link>
 <Link to="/products/articles/Schlösser" className="hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-0.5 before:origin-right before:transition-transform before:duration-500 before:scale-x-0 before:bg-cp-green before:absolute before:left-0 before:bottom-0"><p>Schlösser</p></Link>
 <Link to="/products/articles/Beleuchtung" className="hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-0.5 before:origin-right before:transition-transform before:duration-500 before:scale-x-0 before:bg-cp-green before:absolute before:left-0 before:bottom-0"><p>Beleuchtung</p></Link>
 <Link to="/products/articles/Trinkflasche-und-Getränkehalter" className="hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-0.5 before:origin-right before:transition-transform before:duration-500 before:scale-x-0 before:bg-cp-green before:absolute before:left-0 before:bottom-0"><p>Trinkflasche und Getränkehalter</p></Link>
 <Link to="/products/articles/Glocken" className="hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-0.5 before:origin-right before:transition-transform before:duration-500 before:scale-x-0 before:bg-cp-green before:absolute before:left-0 before:bottom-0"><p>Glocken</p></Link> */}
 
</div>
                  ))}
                 
                </li>
              </ul>
            </div>
              <div>

              </div>
              </div>
              </div>
              <Link to="/service">
                <li className="cursor-pointer">Service</li>
              </Link>
              <Link to="/contact">
                <li className="cursor-pointer">{t("contact")}</li>
              </Link>
              {/* <Link to="/handlersuche">
                <li className="cursor-pointer">{t("Dealersearch")}</li>
              </Link> */}
              </ul>
            </div>
            <div onClick={menuClosed} className=" flex justify-center items-center flex-1">
          <Link to="/">
            <img
              src={logo}
              className="lg:h-[170px] xs:h-[125px] md:h-[210px] md:w-[210px] xs:w-[125px] lg:w-[170px] object-contain "
              alt="logo"
            />
          </Link>
        </div>
        <div className="flex justify-end items-center md:gap-5 xs:gap-3 text-cp-white font-square font-semibold flex-1">
        <div
              onClick={() => handleSelectedLanguage("de")}
              className={`xs:ml-0 cursor-pointer flex items-center gap-1 
            xs:text-[12px] md:text-[15px] ${
              currentLanguage === "de" ? "underline underline-offset-4  font-bold" : ""
            }`}
            >
              <h2 className="text-[15px] ">DE</h2>{" "}
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEFklEQVR
      4nO2ZyWsbVxzHfyRuTj2k7Sk0PXVvkrZuvMRLLC9ZJEuyLGkkWdbMaGYeKOCUJj2FQhr50uDkUru30j8gIdBSQintydCcCgUtljcocWulkSXZlhfsmiTSt8
      yYFCwKkqxtCv7C56aBz/e9p3kz84gOcpCDlJ1gMHiIMdYiy/JVSZLuiaIYFkUxLoritiAI2zzPx30+X9jn893zer1X3G53s3oN1TuSJL2mKEpQkqQ/ZFmG
      JEnw+/0QRVFDEATwPK/h8/kwPDwMr9erMTQ0tODxeG7Y7fbjNRf3+/1HGWNjsizvqOL7kIfH44Hb7YbL5XrCcdyEzWY7WhN5RVGcjLGUoigoV969WwAcx8
      HpdKacTqejauLBYLBBHXXGWK7S8txuATgcDtjt9q8DgcALFZV3uVxHGGM/MMZQZXmoDA4O3q9YCZfLdVhRlLs1lIfNZlP5zmAwNJRdgDH2VR3kMTAwAIvFMl
      6WvCzLphqsefyXvNVqVQvkLBaLdV/ygiC8oihKuo7yUDGbzSmz2fxSyQUYY7d1II/+/n6YTKaxkuQDgcAxWZa3dCIPo9G4bTQai9+x06O3H2xah7Bp8WDTrO
      LGRr8bGyYXNkwcNowc1i86sX7Bsct5O9bODWKtT8WGtd4BZHpUrMh0W5ExWLDaZd7lbD9WO01Y6TBipd2IlbaLWDlzAcut57Hceg7LLX1IN/ch3dSL9OkepD/q
      RqrRgFlh5Jei5DE52ZDhLz3Tk3zqwy7EOy1PJw3BwrfVxPg3st7kUx90Ifn+WYQ+vsYXLJAavfWzHuWTpzoR40d+LFhg5er1R3qUT57swLzVt1iwwKo0sqNH+eS
      Jdjzssv5dsECG8+f0KL90og3x5t5swQLrliFdyi+914bEqQ4UUcCjS/mld8/gr5PthQtkHEJOj/KJd1qx2NhdeAkt85d29CifeLsFv7eZCv+Jk5evxfUon3irGTN
      GrvBt9NFnX/ykR/nEm00IcVLhjezPm+N+Pco/fqMJv0qXh4t6mFu2+5/pTX6hsefpZLHvyPFPbzzQk/zj108jNMAX9zitzcIMHcvO0VZunvAvc3nM7oLnzOQxnUcs
      j6k8onlE9rCNEL1KpSQ7T7d0Ig+E6GZJ8tosxOjl7ByldCCfRJRKf6nXSsyRKTtLuTrK5xCm/X1WeZ7sHE3URT6s8WVZ8tosgA5nZ+lOzeVD9C0mqfxPi1qJGB3BDN
      2v4ch/j9+osl+o1ZnANI1hmnJVXfMhmqi4/J4is+TENKWqMPIphKl6Bxx7SsToRUzTKGK0UwH5J9qoh6g2R0x7ikToOGL0OaZoYR/yDxGh6yXvsFUpAjqEKDVhiq4
      gSncRpRCitIgobWlEaBERCiFCdxCmT7Tfgup/zHqQg9D/P/8A9+JSzY86f54AAAAASUVORK5CYII="
                className="md:h-5 xs:h-4 md:w-5 xs:w-4 object-contain "
              />
            </div>

            <div
              onClick={() => handleSelectedLanguage("en")}
              className={`flex items-center gap-1 xs:text-[12px] 
             md:text-[15px] cursor-pointer ${
               currentLanguage === "en" ? "underline underline-offset-4 font-bold" : ""
             }`}
            >
              <h2 className="text-[15px]">EN</h2>{" "}
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXB
    IWXMAAAsTAAALEwEAmpwYAAAJIUlEQVR4nO3YaVBUVxYH8K5YNZl8ADdAK3FiTExiNBpR4xYRBVxBQbHZV1EhGkQk5a44Lrgk
    bIKArLJDNzvNJgg0O2ojjaAsAgoNyGIjmlg1aM9/6l3nvVHTj8bgGD9wqs733+06/b/nXQ5ntEZrtEZcAMYciL5h3tQhPVT
    f1psodXB5KrW0lw1sssSAgQWkpttlfdscn4qbJInihvZDzqHFZgA+4PzVdYRfrc71FBbPckl9pmQTi4b2PtS39eKJnjGe6Brj
    8XojPF7HxeN1W/B4rSGqG9txs6ENYy0jMMuJN7j5THaRU2DB3HcO/yW1fpqtX2nlZIcEKNnEgcIr28QS/J37PXLxA2sMCb6q/j7G
    WoZjHNUWlzHJNgL6pwRVzr4F098Jfndo5fl/7Er8t5Jt3Ct4ZZsYgr99r1sufmD1ZoIX3W5l8ONIh5Geui1CtsM758z/De5c1vbRZg9
    hjfLWeMjDK1vHEHxd6wO5+IFVmwj+Rl3LH/DjzEMx3jwUEyxCoHc8udrZnffRW8WnlNxRWnPmaoeSLTte2Tqa4GubO+XiH+kYEPy12ma5
    +PHmIRhvHozxZsHQ2BfXtZ+XO/at4L0bGz/UPpXbpQhPNYWvudshF/9IW5/gK2/dHRI/3iyItOa++AeuBQV/H/EBWrsHgrwzb0PNnj8kXt
    k6iuDFTRK5+Eda+gRfUdOkED/ZMhgHgq4gt7TKf0T47pCY0/frWtDc9Qi5Ygnm7Rew4sdaRRE8FZXy8P0rNxJ8ubhxSPzsnZEITS+l8ChMzY
    X44MkTfwrfFcGbNmDpIHtishUdyTlo6pBC3NIDK1+hXPxYq0gm5+Xh+1dsIPiym/WseIMTScgQigj+mm8YupatR4eGrkx0wO3NI7ZdkJ/6xMQO
    v+mbke456Y6GxnYSlb+mVENte9wreKrpnJeH79fUI/jSqjt/wKtZhsDFPxt5ZTeRf7UcDfYu6J23Aj3qmuhavBqlHgFJb4TfH1OtnlcjGWytbYH
    U5Sh+22iKJxtM0G/lgOa8MpI2aRVN+G5vMoOnblc65+Xh+zV1Cb5YdPsV/OyfohAuKCf40pgUSNZyGXyrvgXy+BkITCoY3OWRNm/YB+B6Fpaobuf
    hcKwId+51QxISg8f6Zi9uWD0TSDwCcKuhHddvt8HcI4/gqaZzXh5eulyX4Itu1DF4gxNJyCwS4WppFWr+6Y6ehToE3z1vBWp+dkVGbil+vpiOj80D
    sO5gVPGw8DwexnyxO+U5nTarTuVAeKsdd4XXILXexVxSvfbOqCu9ScbmdHwl1GyjmJyXh5dqrCd44fVaTLYKwdHQq8gvr0ZRRj7uGduhd/5KgpdoGa
    A4JA5RgmJo7o3ERGM/TDS+iOm2l57zeLwxCg+wJ7TS+vWo/MSBB690MerqWtBxyoPJ+f6NprgbGkt++YTCWibn5eGlP6wj+MLKGoRnVBD8Db/L6Fqux+
    AbbB2Rm1mIY0FZ+NTqEoOfaES1L6xOx1soPAC1EtNbJb3b0DcsnfN02tAzT//ydM7Lwz9cupbgCyrEBE/NfG5pFa6UiJBTdB3ZwmvIKqxERkEFBPnl
    SM8rRVpuCVKvFCMlpwjJ2UIkZBYcUHiA+vbepJHgqaiUh3+4ZM2I8IlZheALrvIVHkC6Y+9Ttn2e7YZlm/mX8X2LV6FvkQ76Fmqjd4EWMzY96pro
    mbscPd9poHv2MnR/+wMezFqKBzOX4ME3i9E1YxG6vlqIri+/R+Ny3aeKD2CxQ/Y+4junL0DrAu3nCg9AfQa+j/jOL+ajfcYiKD6AgcV7ie/8fB7a
    vh7GAaRm22XvI75jmjqa1VcoHqE+O8en7yO+47O5aFyy9neFBxA3SpKGE5X0YkavB/QlRUUlG344UZmQkU/FJXjpeYhPy0Vc6hXEpOQgOikLUQkZ
    imOUercZCZ7KebZffkT4xExE8AX7FR7AMbjI8sVy9r+njynbonCOV4bKyhrcc3FlxqZPexPEfuEE780vYi4ptrGh8WcvZxN80TlfSNRXMGNTs2Ur
    kuLTsO9CAqaa+UB1ixdUt3i+aEMPmB4LMx/eMrcz9hmN1zqSioyyOlSn5aLb0IrBd5jZoyJLiPwKMX68kAUVs0DmhmWbefqXVzW+CLuzCUjKLoQgOhkNa7jMzLc
    u0EaWZyD8YgRY5hjI4KdbXnjm6uo6vNe8TW4Zxao2EdgbXIjy6no0/uoPqdZGgn+oqYfGY+dRVCFGurAK2gf5mGAaSJpeD9j+sPTYUIuZipEvljmFITTxChLS
    8lDmdASS6d+TP6zkc3WUb3VCFC8dO8/G4BMjL6x2DiriDLeo5z5+Qc2gqLASnVt3M2nTrW8OUXw6mfnA1DJ8aR/O4CeYXmJ2G7a0oWeewqsY+UCF64Mvrf1wL
    iydzLzAJwR3F2iTxKG6XkMP/IAI/BKc+C8z17A3e4KsvcxPfriGy+Bb9xxBSYmI4A+F5EHVMvgVPNX0YsYWlfQflsarcC+QnmR8AdtOxyI6OQfxMSmoMrRhDn
    F/xmJkHTyZwHnTuuPp+VmvnrGsb7UhbgVEkrTJLhNjw/Ek8jX1On6CSQCzVbLlPJ02L+NVuN5Q3UK1F1buCUZQrACRCRnIPnoW979ZgpZvl8qy7Zz/3Ltpo5vXy
    crcEoKPzr6OOT9FseKppnOe7ZKio1IeXvW/aTPTxgdnAxMRzktHnH84hHZOxzkjqaIbdQHHwwvJZ+BQ+Akm/kzOs92wdM6z4VUNPUh/zPWEg1sEQmOTfTkjLa4r
    728a++IkivATTfyZnGdbD+hLaii8mqE71Da7Y4mDb+da78wPOW+jzqWUKOkciJMMhae+X+mcZ9tt6Bt2OHhH70hlztss6slb9yj/5kQz+Xiq6ZxnW8zo9YANP8n
    QHTq7/UVv7ZeXV9bnUtymWgfLXsdTrwd0zrNtlfRuIw8/zdRLxj0cfIrzLmq/V9qn3BO8simWAa88fdA5z7YS04vZy/gpRp7Q2X1JxD0cOI3zruvH8/w5uoeihTO
    3BQ9S6wGd82z7PL1VUvCZNj6D6/YGFdqdvjyH81eXqys+sHXjm1LvNtTTR5PGht9b5mvJqG/Ytq8Xonmupoz6GKH2eWoltnCNMBn2YjZaozVanKHqPzgpMP8urQK
    eAAAAAElFTkSuQmCC"
                className="md:h-5 xs:h-4 md:w-5 xs:w-4  object-contain"
              />
              
            </div>
            </div>
       
        <div className="absolute inset-x-0 w-full  bg-cp-green h-1 bottom-0 xs:block lg:hidden"></div>
        </div>
        <div className="lg:hidden xs:block  absolute z-50  w-full bg-cp-white">
        {open ? (
          <div className=" animate-fade ">
            <ul className="font-square font-semibold min-h-screen h-auto mt-[5px] xs:text-[16px] lg: md:text-[24px] bg-cp-black text-cp-white relative pt-8">
              
              <div className="flex justify-end items-center xs:mr-8 md:mr-16"> <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACX
BIWXMAAAsTAAALEwEAmpwYAAABI0lEQVR4nO2V3WrCQBCF0zeo9bpN671IdrT0bYTUXQk+TlEzNdC3qdIb
b7VUJU+yMviDNu7uJClSwYG9ytl8OSe7M553rYusaFCvKISJRPgOk8B36cMk8ElLe15HL3csSPujcati8aUQ9Hal0a
BZM+kVwoNEsdjrYzHtvbWqTpCKYXwA2W1ennIWJoFPz7J6GNsp2rtRsZhnNp6AhSbIRjund9kd/Y7ieKWd9+cnl8YWdSYSibAyOTM5o
T3dYeORBWE606WclIClFGkhSA5YWhrCBUVFI8sB0ef8P7pwhLbLSEfYdvQ5vfHPLmzH6czSgsgFtwVJFDNOC5q4IE6Ys6kaxoQti
qjfvFcIP7nHBBUNL/oqioA/+MRMInyyB9+1/l2tAQRe3nYLbYD3AAAAAElFTkSuQmCC"
                className=" xs:h-4 xs:w-4 md:h-7 md:w-7 mb-5"
                onClick={menuClosed}
              /></div>
             <div className="xs:pl-6 md:pl-20">
              <Link to="/products">
                <li className="cursor-pointer  mt-10" onClick={menuClosed}>
                  {t("products").charAt(0) +
                    t("products").slice(1).toLowerCase()}
                </li>
              </Link>
              <Link to="/service">
                <li className="cursor-pointer  mt-8" onClick={menuClosed}>
                  Service
                </li>
              </Link>
              <Link to="/contact">
                <li className="cursor-pointer  mt-8" onClick={menuClosed}>
                  {t("contact")}
                </li>
              </Link>
              {/* <Link to="/handlersuche">
                <li className="cursor-pointer  mt-8" onClick={menuClosed}>
                  {t("Dealersearch")}
                </li>
              </Link> */}
              <Link to="/datenschutz">
              <li className="cursor-pointer  mt-8 " onClick={menuClosed}>
                {t("dataprotection")}
              </li>
              </Link>
             <Link to="/impressum">
             <li className="cursor-pointer  mt-8" onClick={menuClosed}>{t("imprint")}</li>
             </Link>
              
              </div>
              <div className="md:hidden">
              <div class="w-44 overflow-hidden inline-block relative">
                <div class=" h-[250px]  bg-cp-green -rotate-45 transform origin-bottom-right"></div>
              </div>
              </div>
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="absolute inset-x-0 w-full  bg-cp-green h-1 bottom-0 xs:hidden md:block"></div>
      </div>
    </div>
  )
}

export default HeaderSection