import React from "react";
import services from "../Data/services.json"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const ServiceCard = () => {
  const {t}= useTranslation()
  const {i18n}= useTranslation()
  const currentLanguage= i18n.language
  // const images = [{name:t("Dealersearch"),image:"",description:t("dealersearchdes"),"link":"/handlersuche"},
  // {name:t("intructions"),image:"https://media.bbf-bike.de/shop/documents/websites/cp-cycleparts/images/cardsimages/service2.jpg",description:t("heinweis"),"link":"/hinweise"}, 
  // {name:"Downloads",image:"https://media.bbf-bike.de/shop/documents/websites/cp-cycleparts/images/cardsimages/service3.jpg",description:t("downloads"),"link":"https://media.bbf-bike.de/shop/documents/websites/cp-cycleparts/de/downloads/CP_cycleParts_Katalog.pdf"}];
  return (
    <div className="flex justify-center items-center ">
<div className={`grid ${services.services.length >=3? "lg:grid-cols-1 ":"lg:grid-cols-1"} xs:grid-cols-1 xs:gap-5 md:mt-32 lg:mt-0 lg:mb-[80px] md:grid-cols-1 xs:mb-12  xl:gap-28 auto-rows-max`}>
      {/* {services.services.map((item, index) => ( */}
        <div
     
          className="flex flex-col lg:mt-[100px]  font-Manrope-ExtraLight w-[300px] justify-center items-center cursor-pointer 
          xl:transition xl:ease-in-out xl:delay-150  xl:hover:-translate-y-1 xl:hover:scale-110  xl:duration-300"
        >
          <Link to={services.services[1].link}>
          <div className="h-[280px] xs:w-[320px] lg:w-[300px]">
            <img
              src={services.services[1].image}
              className="object-cover h-[280px] xs:w-[320px] lg:w-[300px]"
            />
          </div>
          <div className="h-[42px] text-cp-white font-Manrope-ExtraLight text-[17px] flex justify-center items-center">
           {currentLanguage==="de"?services.services[1].name.de:services.services[1].name.en}
          </div>
          <div className="relative bg-cp-white h-[250px] xs:w-[320px] xl:w-[300px] ">
            <div className="flex justify-center items-center">
              <div className=" xs:px-12 xl:px-8 text-left xs:text-[15px] md:text-[15px] xl:text-[17px] xs:mt-5 mt-5">
                 {currentLanguage==="de"?services.services[1].description.de:services.services[1].description.en}
              </div>
            </div>
            <div class="w-12 overflow-hidden inline-block absolute bottom-0">
              <div class=" h-[250px]  bg-cp-green -rotate-45 transform origin-bottom-right"></div>
            </div>
          </div>
          </Link>
        </div>
      {/* ))} */}
      </div>
    </div>
  );
};

export default ServiceCard;
