import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CategoryCard from "./CategoryCard";

const Subcategory = () => {
  const { productname } = useParams();
  const [category, setCategory] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const products = async () => {
    try {
      const response = await axios.get(
        "https://data.bbf-bike.de/catalog/list/categories/37"
      );
      setCategory(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    products();
  }, []);
   
  const {i18n}= useTranslation()
  const currentLanguage= i18n.language
  const germanDes=category
  ?.filter((item) => item.bbf_cat_name === productname)
  .map((item) => item.translations.german.DESCRIPTION)
  .find((item) => item);

  const englishDes=category
  ?.filter((item) => item.bbf_cat_name === productname)
  .map((item) => item.translations.english.DESCRIPTION)
  .find((item) => item);

  const publicId = category
    ?.filter((item) => item?.bbf_cat_name === productname)
    ?.map((item) => item.bbf_cat_ID_PUBLICGRPORDER)
    .find((item) => item);
  const SubcategoryList = category?.filter(
    (item) => item?.bbf_cat_parent_category === publicId
  );

  
  return (
    <div className="flex justify-center flex-col items-center bg-cp-black md:my-[5px] lg:my-[10px] xs:my-[5px]">
       <div className="text-cp-green md:text-[32px] xs:text-[20px]   font-Manrope-ExtraLight font-bold xs:pt-8 md:pt-12 xl:pt-20">
        {productname}
      </div>
      {currentLanguage==="de"?<h2 className="text-cp-white xs:w-[85%]  md:w-[70%] xl:w-[50%] text-justify xs:mt-4 md:mt-8 xs:text-[12px] md:text-[15px] xl:text-[18px] [font-Manrope-ExtraLight">
        {germanDes}
      </h2>:<h2 className="text-cp-white xs:w-[85%]  md:w-[70%] xl:w-[50%] text-justify xs:mt-4 md:mt-8  xs:text-[12px] md:text-[15px] xl:text-[18px] [font-Manrope-ExtraLight">
        {englishDes}
      </h2> }
        {/* <h2 className="text-cp-white xs:w-[80%]  md:w-[70%] lg:w-[50%] text-justify mt-8 xs:text-[12px] md:text-[15px] xl:text-[18px] [font-Manrope-ExtraLight">
        {germanDes}
      </h2> */}
      <div className="flex justify-center items-center">
        <div
          className={`grid ${
            SubcategoryList.length <= 2
              ? "grid-cols-2 md:gap-28 xs:gap-6"
              : "md:grid-cols-3 lg:grid-cols-3  xs:grid-cols-2 md:gap-5 xl:gap-28 xs:gap-6"
          }  md:py-16 xs:py-8 mt-[10px]`}
        >
          {SubcategoryList.map((item, index) => (
            <div key={index} >
              <Link
                to={`/products/articles/${item.bbf_cat_name.replace(/\s+/g,
                  "-"
                )}`}
              >
            <CategoryCard image={item.bbf_cat_symbol} name={currentLanguage=="de"?item.bbf_cat_name:item.translations.english.NAME}/>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Subcategory;
