import React from "react";
import logo from "../Images/CycleParts_grün_weiß.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const {t}= useTranslation()
  const date= new Date()
  const year= date.getFullYear()
  return (
    <div className="">
    <div className="w-full bg-cp-black relative  font-square lg:h-[150px] xs:h-[280px] md:h-[380px]">
      <div className="flex lg:flex-row xs:flex-col items-center xs:justify-center xl:justify-between text-cp-white font-semibold xs:gap-0 xl:gap-6 2xl:gap-10 xl:px-5 2xl:px-28 xl:h-[120px] ">
      <div className="xl:text-[16px] md:text-[16px] xs:text-[10px] sm:text-[12px] xs:mt-[30px] lg:mt-0 flex-1 flex xs:justify-center xl:justify-start items-center">{t("footertag")}</div>
  <div className="flex-1 flex justify-center items-center">
  <img
            src={logo}
            className="lg:h-[170px] xs:h-[125px] md:h-[210px] md:w-[210px] xs:w-[125px] lg:w-[170px] object-contain"
          />
  </div>
  <h2 className="xl:text-[16px] xs:text-[10px] md:text-[16px] sm:text-[12px] xs:mb-[30px] lg:mb-0 flex-1 flex justify-end xs:gap-1 2xl:gap-2 items-center">
    {/* <Link to="/handlersuche">{t("Dealersearch")}</Link> 
    |  */}
    <Link to="/datenschutz">{t("dataprotection")}</Link> 
    | <Link to="/contact">{t("contact")}</Link> | <Link to="/impressum">{t("imprint")}</Link> 
    
    <div className="cursor-pointer flex xs:justify-center xl:justify-end items-center xs:ml-2 2xl:ml-2 xl:ml-1">
        <a href="https://www.instagram.com/cpcycleparts/" target="_blank"> <img width="30" height="30" src="https://img.icons8.com/fluency/48/instagram-new.png" alt="instagram-new" className="xs:h-6 xs:w-6 md:h-7 md:w-7 object-contain"/></a>
       </div>
    
    </h2>

    
        {/* <div className="xl:text-[14px] md:text-[16px] xs:text-[10px] sm:text-[12px] xs:mt-[30px] lg:mt-0">{t("footertag")}</div>
        <div className="xl:mr-32 xs:mr-0">
          {" "}
          <img
            src={logo}
            className="xl:h-[140px] xl:w-[180px] xs:h-[100px] xs:w-[120px] object-contain lg:ml-20 xl:ml-44 2xl:ml-60"
          />
        </div>

        <h2 className="xl:text-[14px] xs:text-[10px] md:text-[16px] sm:text-[12px] xs:mb-[30px] lg:mb-0"><Link to="/handlersuche">{t("Dealersearch")}</Link> | <Link to="/datenschutz">{t("dataprotection")}</Link> | <Link to="/contact">{t("contact")}</Link> | <Link to="/impressum">{t("imprint")}</Link> </h2> */}
      </div>

      <div className="absolute font-Manrope-ExtraLight inset-x-0 w-full bg-cp-green lg:h-[25px] xs:h-[50px] bottom-0 left-0  flex items-center justify-center xs:text-[11px] lg:text-[12px] xl:text-[14px] xs:px-8 xs:text-center  lg:px-0">
        <div>
       www.cp-cycleparts.de © {year} | {t("footertaglinegreen")}
        </div>
      </div>
      {/* <div className="xl:ml-10 lg:ml-4 xs:ml-0 cursor-pointer xs:mb-[70px] lg:mb-0">
        <a href="https://www.instagram.com/cpcycleparts/" target="_blank"> <img width="30" height="30" src="https://img.icons8.com/fluency/48/instagram-new.png" alt="instagram-new" className="xs:h-6 xs:w-6 md:h-7 md:w-7"/></a>
       </div> */}
    </div>
    </div>
  );
};

export default Footer;
