import React, { useEffect, useRef } from "react";
import Product from "../Components/Product";
import MainProductCard from "../Components/MainProductCard";
import mainImage from "../Images/productMainImage.jpg";
import { useTranslation } from "react-i18next";
const ProductPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();
  const scrollPosition= useRef()

  return (
    <div className="bg-cp-black">
    <div className="min-h-[calc(100vh-440px)] h-auto max-w-[1920px] m-auto">
      <div className="md:mt-[100px] bg-cp-black flex justify-center items-center relative">
        <img
          src={mainImage}
          className="h-full xs:object-contain md:object-cover
          w-full md:px-20 lg:px-24 xs:px-6"
        />
        <div className="absolute md:w-[400px] xs:w-[290px] xs:mx-10 xs:inset-x-0 xs:text-[15px] md:text-[22px] xl:text-[38px] font-Manrope-ExtraLight  text-cp-white md:left-[50%] xl:left-[48%] xs:top-[45%] md:top-[40%] xs:left-[30%] xl:top-[32%] 2xl:left-[55%] 2xl:top-[32%]">
          {t("welcomeproduct")}{" "}
          <b className="xs:text-[18px] md:text-[25px] xl:text-[42px]">
            CP CYCLEPARTS
          </b>{" "}
          {t("products")}
        </div>
      </div>
      <div className="xs:h-[5px] lg:h-[10px] bg-cp-white"></div>
      <MainProductCard />
      <div className="xs:h-[5px] lg:h-[10px] bg-cp-white"></div>
    </div>
    </div>
  );
};

export default ProductPage;
