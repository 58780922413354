import React from "react";
import image from "../Images/GPT-Tasche-mit-Columbus.jpeg2.jpg";
import logo from "../Images/CycleParts_grün_weiß.png";
import { useTranslation } from "react-i18next";
import bannerImage from "../Images/descriptionPicture.jpeg";
const DiscriptionSection = () => {
  const {t}= useTranslation()
  return (
    <div className="md:my-[10px] xs:my-[5px] overflow-hidden  flex xl:flex-row xs:flex-col  justify-between items-center">
      {/* <div className="lg:my-[10px]   flex xl:flex-row xs:flex-col  justify-center items-center">
        <img
          src={logo}
          className="object-contain xs:h-24  xs:w-24 md:h-32 md:w-32 xs:block  lg:hidden"
        />
        <img
          src={image}
          className=" object-cover xs:w-full xs:h-[250px] lg:h-[calc(100vh-100px)] xl:w-[800px]"
        />
        <div className="flex flex-col justify-center items-center">
          <img
            src={logo}
            className="object-contain h-44 w-44 xs:hidden lg:block"
          />
          <div className="text-justify xl:mx-24  xs:mx-10 font-Manrope-ExtraLight xs:py-5 xl:my-0 xs:text-[12px] md:text-[15px] xl:text-[18px] xs:mt-[10px] xl:mt-0">
          {t("descriptionsection")}
            <br />
          </div>
        </div>
      </div> */}


<div className="xs:flex 2xl:hidden xs:flex-col xl:flex-row bg-cp-black">
    <img
          src={bannerImage}
          className="object-cover xs:h-[250px] xl:h-[550px]"
          alt="banner"
        />

      <div className="flex-col justify-center  flex xs:mx-6 my-5 ">
      <div className="flex flex-col justify-center items-center">
      <img
            src={logo}
            className="object-contain xs:h-28 xs:w-28 xl:h-36 xl:w-36"
          />
          <div className="text-left text-cp-white  font-Manrope-ExtraLight xs:py-5 xl:my-0 xs:text-[12px] md:text-[15px] xl:text-[18px]  xs:mt-[0px] xl:mt-0">
          {t("descriptionsection")}
            <br />
          </div>
        </div>
      </div>
    </div>
    <div className=" justify-start relative w-screen 2xl:flex xs:hidden">
 <div
        className="2xl:h-[500px] xl:h-[400px] md:h-[400px] md:w-[64%] lg:w-[45.5%] xs:h-[250px] bg-cp-black xs:w-[62%] xl:w-[70%] 2xl:w-[1080px] flex float-left aspect-[1/1] lg:aspect-[1/2]  
                                      [clip-path:circle(70%_at_20%_30%)] 
                                      [shape-outside:circle(70%_at_20%_30%)]
                                      2xl:[clip-path:polygon(0%_0%,100%_0%,100.3%_100%,0%_100%)]
                                      2xl:[shape-outside:polygon(0%_0%,100%_0%,100%_100%,0%_100%)]  
                                      xl:[clip-path:polygon(0%_0%,100%_0%,100%_100%,0%_100%)]
                                      xl:[shape-outside:polygon(0%_0%,100%_0%,75%_100%,0%_100%)] 
                                      lg:[clip-path:polygon(0%_0%,100%_0%,100%_100%,0%_100%)]
                                      lg:[shape-outside:polygon(0%_0%,100%_0%,75%_100%,0%_100%)]"
      >
           <img
          src={bannerImage}
          className="w-full object-cover"
          alt="banner"
        />
      </div>

      <div>
      <div
        className="2xl:h-[500px] xl:h-[400px] md:h-[400px] md:w-[64%] lg:w-[45.5%] xs:h-[250px] bg-cp-black xs:w-[62%] xl:w-[70%] 2xl:w-[835px] flex float-left aspect-[1/1] lg:aspect-[1/2]  
                                      [clip-path:circle(70%_at_20%_30%)] 
                                      [shape-outside:circle(70%_at_20%_30%)]
                                      2xl:[clip-path:polygon(0%_0%,100%_0%,100.3%_100%,0%_100%)]
                                      2xl:[shape-outside:polygon(0%_0%,100%_0%,100%_100%,0%_100%)]  
                                      xl:[clip-path:polygon(0%_0%,100%_0%,88.2%_100%,0%_100%)]
                                      xl:[shape-outside:polygon(0%_0%,100%_0%,75%_100%,0%_100%)] 
                                      lg:[clip-path:polygon(0%_0%,100%_0%,88.2%_100%,0%_100%)]
                                      lg:[shape-outside:polygon(0%_0%,100%_0%,75%_100%,0%_100%)] rotate-[360deg] "
      >
           <div className="flex flex-col justify-center items-center">
          <img
            src={logo}
            className="object-contain h-36 w-36 xs:hidden lg:block"
          />
          <div className="text-left text-cp-white xs:mx-10 font-Manrope-ExtraLight xs:py-5 xl:my-0 xs:text-[12px] md:text-[15px] xl:text-[18px] xs:mt-[10px] xl:mt-0">
          {t("descriptionsection")}
            <br />
          </div>
        </div>
      </div>

      {/* <div className="flex flex-col justify-center items-center">
          <img
            src={logo}
            className="object-contain h-28 w-28 xs:hidden lg:block"
          />
          <div className="text-justify mr-40 xs:mx-10 font-Manrope-ExtraLight xs:py-5 xl:my-0 xs:text-[12px] md:text-[15px] xl:text-[18px] xs:mt-[10px] xl:mt-0">
          {t("descriptionsection")}
            <br />
          </div>
        </div> */}
      </div>
      </div>
    </div>
  );
};

export default DiscriptionSection;
