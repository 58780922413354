import React from 'react'

const Pagination = ({itemsPerPage,totalItems,selectedPage,currentPage}) => {
    const pageNumbers=[];
    for(let i=1 ; i<=Math.ceil(totalItems/itemsPerPage);i++){
        pageNumbers.push(i)
    }
  return (
    <div className='text-cp-black flex justify-center items-center gap-3 xs:mb-5 xl:mb-16'>
        {pageNumbers.map((number,index)=>(
            <div key={index}  className={`border-2 border-cp-white  w-6 flex justify-center items-center font-bold  ${currentPage===number? "bg-cp-green":" bg-cp-white"}`}>
                <a href={`#section`} id='' onClick={()=>selectedPage(number)}>
                    {number}
                </a>
            </div>
        ))}
    </div>
  )
}

export default Pagination