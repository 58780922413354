import React, { useEffect,useRef } from 'react'
import Subcategory from '../Components/Subcategory'

const SubCategoryPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const scrollPosition= useRef()
  return (
    <div className='bg-cp-black'>
    <div className='lg:min-h-[calc(100vh-140px)] h-auto max-w-[1920px] m-auto'>
    <div className='md:mt-[100px] bg-cp-black flex justify-center items-center relative overflow-hidden'>

       
        </div>
       
          <Subcategory/>
    
  </div>
  </div>
  )
}

export default SubCategoryPage