import React from 'react'
import image from "../Images/downloads.jpg"
import { useTranslation } from 'react-i18next'
import services from "../Data/services.json"
import { useEffect } from 'react'
const Downloads = () => {
    const {i18n}= useTranslation()
    const currentlanguage= i18n.language
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
  return (
<div className="bg-cp-black">
    <div className="min-h-[calc(100vh-140px)] h-auto max-w-[1920px] m-auto">
        
        <div className='xs:pt-2 lg:pt-[40px] xl:pt-[100px]'>
            {
                services.services.filter((item)=>item.name.de==="Downloads").map((item,index)=>(
                    <div key={index} className='flex justify-center items-center flex-col'>
                          <p className='text-cp-green md:text-[32px] xs:text-[20px]   font-Manrope-ExtraLight font-bold xs:pt-8 md:pt-[140px] xl:pt-20 '>
                        {currentlanguage==="de"?item.name.de:item.name.en}</p>
                        <h3 className='flex justify-center xl:flex-row xs:flex-col items-start xs:mt-10 xl:mt-20 xs:mb-14 xl:mb-28 xs:gap-10 xs:mx-5 xl:mx-0 xl:gap-20'>
                            <img src={image} className='object-contain'/>
                            
                            <div className='flex justify-center  items-start flex-col gap-4'>
                            
                                                        {item.documents.map((item,index)=>(
                            <p key={index} className='text-cp-white font-Manrope-ExtraLight flex justify-center items-center xs:text-[12px] md:text-[15px] xl:text-[18px]  xl:transition xl:ease-in-out xl:delay-150  xl:hover:-translate-y-1 xl:hover:scale-110  xl:duration-300'>
                                <a href={item.link} target='_blank' className='hover:text-cp-green'>
                     {item.name}
                                </a>
                          
                        
                            </p>
                        ))}</div></h3>
                    </div>
                  
                ))
            }
           
            <h3></h3>
        </div>
        
        </div> <div className="xs:h-[5px] lg:h-[10px] bg-cp-white"></div></div>
  )
}

export default Downloads