import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import entranslation from "../src/en.json"
import detranslation from "../src/de.json"
import i18n from "i18next"
import { initReactI18next } from "react-i18next";

const savedLanguage = localStorage.getItem('language')

i18n.use(initReactI18next).init({
    resources: {
      en: { translation: entranslation },
      de: { translation: detranslation },
    },
    lng: savedLanguage || 'de', 
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });
  export default i18n
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <App />
    </BrowserRouter>
  </React.StrictMode>
);


