import { useState,useEffect } from "react"
import React from 'react'
import sliderImage1 from "../Images/slider1.jpg"
import sliderImage2 from "../Images/slider2.jpg"
import sliderImage3 from "../Images/silder3.jpg"
import sliderMobile1 from "../Images/slider1_Mobile.jpg"
import sliderMobile2 from "../Images/slider2_Mobile.jpg"
import sliderMobile3 from "../Images/slider3_Mobile.jpg"
import { useTranslation } from "react-i18next"
const SliderSection = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const prevImage = () => {
        setCurrentIndex((prevIndex) =>
          prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
      };
    const {t} = useTranslation()
      const nextImage = () => {
        setCurrentIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
      };
      useEffect(() => {
        const timer = setTimeout(() => {
          const nextIndex = (currentIndex + 1) % images.length;
          setCurrentIndex(nextIndex);
        }, 5000);
        return () => clearTimeout(timer);
      }, [currentIndex]);

      const images= [
        {image:sliderImage1,tagline:t("intro2"),mobileimage:sliderMobile1},
        {image:sliderImage2,tagline:t("intro1"),mobileimage:sliderMobile2},
        {image:sliderImage3,tagline:t("intro3"),mobileimage:sliderMobile3},
    ]
  return (
    <div>
        <div className="relative bg-cp-black">
        {images.map((image, index) => (
          <div key={index} className=" flex justify-center items-center relative">
            <div className="xs:hidden md:block">
            <img
              
              src={image.image}
              alt={`Slide ${index + 1}`}
              className={`object-cover lg:h-[500px] w-screen ${index!==currentIndex?"hidden":"block"} `}
            />
            </div>
            <div className="xs:block md:hidden">
            <img
              
              src={image.mobileimage}
              alt={`Slide ${index + 1}`}
              className={`object-cover w-screen ${index!==currentIndex?"hidden":"block"} `}
            />
            </div>
           
           <div className="h-full "></div>
          
            <div className= {` absolute  text-cp-white xs:bottom-5 md:bottom-5 2xl:bottom-12 md:right-16 lg:right-52 font-Manrope-ExtraLight xs:text-[15px] md:text-[20px] xl:text-[28px] xs:w-[68%]  xl:w-[750px]  ${index!==currentIndex?"hidden":"block"}`}>{image.tagline}</div>
           </div>
        ))}
         <div className="absolute inset-0 text-cp-white flex xs:justify-center md:justify-end  xl:mr-[39%] md:mr-[41%] 2xl:mr-[26%] xs:text-[18px] md:text-[25px] xl:text-[42px] font-bold xs:mt-[30%] md:mt-[8%] xl:mt-[16%] 2xl:mt-[10%] stroke-cp-green  items-start font-Manrope-ExtraLight">Parts for better Cycling</div>
        <button
          type="button"
          className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          data-carousel-prev
          onClick={prevImage}
        >
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsT
          AAALEwEAmpwYAAADEUlEQVR4nO1aS2tTURC+Kx8LRcHXThcuRKw1M/GBKG5EERS7EbQuijVnUoXS1n/hCwyW5ExaKbh0qS3
          qPyji2jZpVvWFoq6tLY3M7W0Syb01uTn3VTpwSEKSc+a735w58ziWtSHrVO7ku3YSYw9pfKgYphTDrGL8RRoXZNjvGWfkO/
          lNtoBX+ya6d1hxkMHcwc2ZItwkxrekYYkYq20NDUuK8TUx9spcVtgy8vjUVtJ4jzR+blt5j6EYP5GG4b6Jc1tCAaE4fYkYK6YA
          NLOEc5kiXAwMgFBPjLnAADSb3XNh3iiI22Mn95LG96GB4BqYdwP5o3uMgMgWug8I3aGD4NreKYsOHYG49ezYbnGjUYGg+qjcHT2+zx
          cI8R6RmBN7m5kvF60YCpErz01gnvpxsdWYjgutu1kNpRgoXHVnBedaOjTtEzsABRTjt8wYIDGe6XwuGGqBDXNhRyOIgXz6yMqDSqU7Z
          wU+rrnxnQAwMBDyKp9NzJst4A1vs5Io1qw9fyfGLmfuQ4rhq7kHBFPe+YSfUDxkJqgOZHEwd2K7Gxs9SWCCGkamCJfdgDxKAhP071oPm
          oDYKWhCmKA6kJcuQLCcFCaotibMNpuWhp9JYYIa1nUBggt+J5QTO0wmqG4Fv9czEDBmWsT4JULTglLSNjsxzoTifoNmRrm5XyljGpo8PGY
          03A81RAmKmYxbiCIF5SQFjcT4xzVodMzrjVnqA2Vm0hWEs1ivafqDYkZx+ronEEkf7ap4zFNdxTB/7cXhTZ5AVhaCYdNAVsEIiEwRzhow2cE
          1QdRZiUWZtOrxQMotVxylPxG1wuTOxLLi9HmrHVGMozEE8qQtEKsmJoXjyJXn2pj+7wb3EmLcFZP9UpFmk9WJSJOl0zS4M3OCUv94ar9lQqT9
          FZGZTUuzyQqkGapxOQwQipED7b1LfyJIU1P2jYk2XaxfcQ7NIamKGwQwLyd2JDcgZFGpijt3TxZ9KC//mZQA0LdrNS2SG5BOXZEyJml85Vyg+V
          G/VGO//yDpqWR2khT1j5/eZlyRDbHiIX8B7eI/yVjSV5MAAAAASUVORK5CYII=" className="md:h-10 md:w-10 xs:h-7 xs:w-7 object-contain"/>
        </button>
        <button
          type="button"
          className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          onClick={nextImage}
          data-carousel-next
        >
     <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADEUl
     EQVR4nO2az2sTURDH9+SPg6Lgr5sePIhYa97EH6jgRRRBsRdB66FY8yZVCK3+F/4CgyV5k1YKHj1qi/ofFPFs2zSn+osW9Wxt6cps1gTMbszuvr
     e7KR14EEiy7332Ozs7M+9Z1oatU7tT6tmJBH2o4JEkMSVJzEqCn6hgmYfzmWCGv+Pf5MtwdWCid4eVBisUD27OVcRNJHiHSqwigR1oKLEqCd4g
     QT9fy4rb7j05tRUV3EcFXwIv3mdIgs+oxMjAxLktsUBIyl5CgpougFaVYD5XEReNAbD0SFA0BtDqdi9Yea0Qt8dO7kUFH2KDoAbM+6HS0T1aIP
     Ll3gMsd+wQ1Hh2qryGSBC3nh/bzWE0KQhsjtrd0eP7QkFw9EjEncjfzUKFaEminPjiqQXmWZgQa6d0XOg8zCoxl4IF296qwHxHL03njR1xsnwZz
     qDKZCXBogkYSWK4AzWipx25MQF8vaFS9ogRGCU+tX3w3QRQwx2DRYYwCZMvww1/t+IsVp8vLyFBj3vdQ0jwVbN7TfnXE2FS8YSUkSRWCsUT273U6
     NMtv2llchVx2QvksREQg8pIgoctIE4JagjElDKS4JUHCFSNghhQRpKYbXUtJX6YBtGujIIlDxBYjgXkH2U4A4hwnV/rGUQk4lqSxDfNriWMZ7wGwv
     BM4uE3khLUJvxyG7OLlLDrN0c8SCxF0aEEtktRuKHcTUkjEvz2TBpd93rbDUpgfUx6QrgT9neBEnZ9jux1XxAuH52ueMpLXUli4drLw5t8QeruJUY
     0THbWZPMBFRTaQjRVSUWb1PZWA6oddxx5fyLpBaO3EmuSsuetICYJRlMI8jQQxF8X48Zx4ounxpj+7wPuZ0iwKyXPS403m6woxpsscZTB/u4k5gb
     HM/stHcbbXwm52TRvNllGNkMVrMUBIQnI6N4770+YdDXpnJgIGGLDmvvSHOauuEaABX5jJ3ICgiflrrh79mQlxOL5P5OcAIYOrbqNawNUmSvcxkQ
     Fr90DNN+bh2qczx+5POXKjouiwfHT27QvZMOsdNgfdws/xw6rX3UAAAAASUVORK5CYII=" className="md:h-10 md:w-10 xs:h-7 xs:w-7 object-contain"/>
        </button>
      </div>
    </div>
  )
}

export default SliderSection