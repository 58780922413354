import React from 'react'
import { useMap,Marker,Popup } from 'react-leaflet'
import marker from "../Images/marker.png"
import locationIcon from "../Images/pin_3177361.png"
import phone from "../Images/phone-call.png"
import web from  "../Images/web.png"
import email from "../Images/email.png"
import L from "leaflet";
const MarkerSection = ({data}) => {
  const map= useMap()
  const customIcon = L.icon({
    iconUrl: marker,
    iconSize: [50 , 50],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });
  console.log(data)
  return (
    <div>
{data? data?.map((item,index)=>(

  <div key={index}>

    {item.position[0] && item.position[1] && (<Marker  icon={customIcon} position={[item?.position[0], item?.position[1]]} eventHandlers={{
  click: (e) => {
    map.setView(e.latlng, 14,{
      "animate": true,
      "pan": {
        "duration": 500
      }}
    );
  },
}}>
 <Popup>
   <div className=" font-Manrope-ExtraLight xs:p-1 xl:p-2 md:w-[250px] xl:w-[400px]">
   <h3 className="text-cp-green xs:text-[16px] md:text-[17px] xl:text-[20px]">{item.name}</h3>
   <div className="xs:text-[11px] md:text-[13px] xl:text-[15px]">
     <div className="flex  gap-3 justify-start items-center">
       <img src={locationIcon} className="xs:h-5 xs:w-5 xl:h-8 xl:w-8 object-contain"/>
       <p>{item.adresse}</p>
     </div>
   <div className="flex  gap-3 justify-start items-center">
     <img src={phone} className="xs:h-5 xs:w-5 xl:h-8 xl:w-8 object-contain"/>
     <p>{item.tel}</p>
   </div>
 <div className="flex  gap-3 justify-start items-center">
   <img src={email} className="xs:h-5 xs:w-5 xl:h-8 xl:w-8 object-contain"/>
   <p>{item.email}</p>
 </div>
 {item.web?( <div  className="flex  gap-3 justify-start items-center">
   <img src={web} className="xs:h-5 xs:w-5 xl:h-8 xl:w-8 object-contain"/>
   <p>{item.web}</p>
  </div>):""}
 
  
   </div>
   
   </div>
  
 </Popup>

</Marker>)}
 
</div>
)):""}
 
    </div>
  )
}

export default MarkerSection