import axios from "axios";
import React, { useEffect, useState } from "react";
import cardImage from "../Images/_MG_9708.jpg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CategoryCard from "./CategoryCard";
const MainProductCard = () => {
  const [articles, setArticles] = useState([]);

  const products = async () => {
    try {
      const response = await axios.get(
        "https://data.bbf-bike.de/catalog/list/categories/37"
      );
      setArticles(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    products();
  }, []);

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const mainCategory = articles
    ?.filter((item) => item.bbf_cat_name === "CP CycleParts Produkte")
    .map((item) => item.bbf_cat_ID_PUBLICGRPORDER)
    .find((item) => item);
  const germanDes = articles
    ?.filter((item) => item.bbf_cat_name === "CP CycleParts Produkte")
    .map((item) => item.translations.german.DESCRIPTION)
    .find((item) => item);
  const englishDes = articles
    ?.filter((item) => item.bbf_cat_name === "CP CycleParts Produkte")
    .map((item) => item.translations.english.DESCRIPTION)
    .find((item) => item);
  const englishpro = articles
    ?.filter((item) => item.bbf_cat_name === "CP CycleParts Produkte")
    .map((item) => item.translations.english.NAME)
    .find((item) => item);
  const germanpro = articles
    ?.filter((item) => item.bbf_cat_name === "CP CycleParts Produkte")
    .map((item) => item.translations.german.NAME)
    .find((item) => item);
  const subCategories = articles
    ?.filter((item) => item.bbf_cat_ID_PUBLICGRPORDERMAIN === mainCategory)
    .sort((a, b) => a.bbf_cat_position - b.bbf_cat_position);
  const countofItems = subCategories.map(
    (item) => item.bbf_cat_ID_PUBLICGRPORDER
  );

  let allAArays = [];
  let emptyArrays = [];
  const findCounts = () => {
    for (let i = 0; i < countofItems.length; i++) {
      let filteredItem = articles?.filter(
        (item) => item.bbf_cat_parent_category === countofItems[i]
      );
      if (filteredItem.length === 0) {
        emptyArrays.push(countofItems[i]);
      }
      allAArays.push(filteredItem);
    }
  };
  findCounts();

  return (
    <div className="flex justify-center flex-col items-center xs:bg-cp-black">
      {/* <div className='bg-cp-green absolute inset-0 opacity-30 md:block xs:hidden'></div> */}
      <div className="text-cp-green md:text-[32px] xs:text-[20px]   font-Manrope-ExtraLight font-bold xs:pt-5 md:pt-12 lg:pt-28">
        {currentLanguage == "de" ? germanpro : englishpro}
      </div>
      <h2 className="text-cp-white xs:w-[85%]  md:w-[70%] lg:w-[50%] text-justify xs:mt-4 md:mt-8 xs:text-[12px] md:text-[15px] xl:text-[18px] [font-Manrope-ExtraLight">
        {currentLanguage == "de" ? germanDes : englishDes}
      </h2>
      <div className="flex justify-center items-center">
        <div
          className={`grid ${
            subCategories.length <= 2
              ? "grid-cols-2 md:gap-28 xs:gap-6"
              : "md:grid-cols-2 lg:grid-cols-3 xs:grid-cols-2 md:gap-28 xs:gap-6"
          }  md:py-16 xs:py-8  `}
        >
          {subCategories.map((item, index) => (
            <div key={index}>
              <Link
                to={
                  emptyArrays.includes(item.bbf_cat_ID_PUBLICGRPORDER)
                    ? `/products/articles/${item.bbf_cat_name}`
                    : `/products/${item.bbf_cat_name}`
                }
              >
              <CategoryCard image={item.bbf_cat_symbol} name={currentLanguage=="de"?item.bbf_cat_name:item.translations.english.NAME}/>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MainProductCard;
