import React from 'react'
import GlobalContact from '../Components/GlobalContact'
import { useEffect } from 'react';

const ContactPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

 
  return (
    <div className='bg-cp-black'>
    <div className='min-h-[calc(100vh-140px)] h-auto md:bg-[url("../public/Images/Contact_Image.jpg")] bg-cover  bg-no-repeat relative xs:mt-[5px] md:mt-0 overflow-hidden max-w-[1920px] m-auto' >
       <div className='bg-cp-green absolute inset-0 opacity-30 md:block xs:hidden'></div>
      <div className=''>
  <GlobalContact/>
      </div>

      </div>
    </div>
  )
}

export default ContactPage