import React from 'react'

const CategoryCard = ({image,name}) => {
  return (
    <div> <div className=" md:w-[220px] xs:w-[150px] relative border-[3px] border-cp-green">
      <div className='md:block xs:hidden'>
      <img
      src={`https://media.bbf-bike.de/shop/documents/websites/cp-cycleparts/images/categories/${image}_Desktop_220x220.jpg`}
      className="object-cover md:h-[220px] xs:h-[150px] bg-cp-white"
    />
      </div>
      <div className='md:hidden xs:block'>
      <img
      src={`https://media.bbf-bike.de/shop/documents/websites/cp-cycleparts/images/categories/${image}_Mobile_150x150.jpg`}
      className="object-cover md:h-[220px] xs:h-[150px] bg-cp-white"
    />
      </div>
    
    <div className="absolute inset-x-0 bottom-0 flex flex-row justify-center items-center left-0">
      <div class="w-8  overflow-hidden inline-block">
        <div class=" h-16  bg-cp-green -rotate-45 transform origin-bottom-right"></div>
      </div>
      <div
        className="md:h-5 xs:h-3 bg-cp-black w-[360px] flex float-left aspect-[1/1] lg:aspect-[1/2]  
                        [clip-path:circle(70%_at_20%_30%)] 
                        [shape-outside:circle(70%_at_20%_30%)]
                        md:[clip-path:polygon(0%_0%,100%_0%,91%_100%,0%_100%)]
                        md:[shape-outside:polygon(0%_0%,100%_0%,85%_100%,0%_100%)] 
                        xs:[clip-path:polygon(0%_0%,100%_0%,91%_100%,0%_100%)]
                        xs:[shape-outside:polygon(0%_0%,100%_0%,85%_100%,0%_100%)]
                        transform -scale-x-100 md:mt-11 xs:mt-[51px] md:-ml-[16px] xs:-ml-[11px] items-center justify-start bg-opacity-75"
      >
        <div className="md:text-[12.5px] xs:text-[9px] text-cp-white md:ml-2 xs:ml-1 font-Manrope-ExtraLight transform -scale-x-100 py-[3px] ">
          {name}
        </div>
      </div>
    </div>
  </div></div>
  )
}

export default CategoryCard